import { call, put, takeLatest } from "redux-saga/effects";
import axiosInstance from "../../../axios/axiosInstance";
import { notify } from "../../../utils/notification";
import {logOut} from '../../../utils/helpers/logout'
import apiEndpoints from "../../../axios/api";
import * as types from "../../constants";
import moment from "moment";
import { notesFilePath } from "../../../firebase/getFirebasePath";
import { deleteImageFromFirebase, getFirebaseURL, uploadImageOnFirebase } from "../../../firebase/firebaseFunction";
import { parseJSON } from "../../../utils/helpers/universalFunctions";

export const getConsultationNotesReq = async({data})=>{   
    return axiosInstance.get(`${apiEndpoints.VIEW_CONSULTATION_NOTES_API}?user_id=${data.guestId}&user_program_id=${data.userProgramId}${data.date ? `&date=${data.date}` : ''}${data.noteType ? `&notes_type=${encodeURIComponent(data.noteType)}` : ''}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);

            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            }else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* getConsultationNotes({data}){
    try{
        let result = yield call(getConsultationNotesReq, {data})
        if(result.statusCode === 200){
            result.data.list = !result.data.list ? [] : yield Promise.all(result.data.list.map(async noteGroup => {
                return {
                    ...noteGroup,
                    list: !noteGroup.list ? [] : await Promise.all(noteGroup.list.map(async note => {
                        let parsed = note.notes_doc ? JSON.parse(note.notes_doc) : [];
                        const notes_url = await Promise.all(parsed.map(async item => {
                            return await getFirebaseURL(item);
                        }));
        
                        return {
                            ...note,
                            notes_doc: parsed,
                            notes_url: notes_url 
                        };
                    }))
                };
            }));
            yield put({ type: types.GET_CONSULTATION_NOTES_DATA_SUCCESS, payload: result.data});  
        }else{
            yield put({ type: types.GET_CONSULTATION_NOTES_DATA_FALIURE});
        }
    }catch(error){
        console.log(error)
        yield put({ type: types.GET_CONSULTATION_NOTES_DATA_FALIURE, message: error.message });
    }
}


export const getNotesTypeReq = async({data})=>{   
    return axiosInstance.get(`${apiEndpoints.GET_NOTES_TYPE_LIST_API}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);

            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            }else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* getNotesType({data}){
    try{
        let result = yield call(getNotesTypeReq, {data})
        if(result.statusCode === 200){
            yield put({ type: types.GET_NOTES_TYPE_LIST_SUCCESS, payload: result.data});  
        }else{
            yield put({ type: types.GET_NOTES_TYPE_LIST_FALIURE});
        }
    }catch(error){
        yield put({ type: types.GET_NOTES_TYPE_LIST_FALIURE, message: error.message });
    }
}

export const deleteConsultationNotesReq = async({data})=>{
    const BODY = {
        "note_id": data.noteId
      }

    return axiosInstance.delete(apiEndpoints.DELETE_CONSULTATION_NOTES_API, { data: {data: BODY} })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* deleteConsultationNotes({data}){
    try{
        let result = yield call(deleteConsultationNotesReq, {data})

        if(result.statusCode === 200){
            for(let i=0; i<data.docs?.length; i++){
                let filePath = data.docs[i];
                yield call(deleteImageFromFirebase, filePath)
            }
    
            yield put({ type: types.DEL_CONSULTATION_NOTES_DATA_SUCCESS, payload: data});
            notify("success", result.message)
        }
    }catch(error){
    }
}

export const updateConsultationNotesVisibleStatusReq = async({data})=>{
    const BODY = {
        "user_program_note_id": data.noteId,
        "is_guest_visible": data.isVisible ? 1 : 0
    }
    return axiosInstance.put(apiEndpoints.UPDATE_VISIBLE_STATUS_CONSULTATION_NOTES_API, {data: BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            }else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* updateConsultationNotesVisibleStatus({data}){
    try{
        let result = yield call(updateConsultationNotesVisibleStatusReq, {data})
        if(result.statusCode === 200){
            yield put({ type: types.UPDATE_CONSULTATION_NOTES_VISIBLE_STATUS_SUCCESS, payload: data});
            notify("success", result.message)
        }
    }catch(error){
        console.log(error)
    }
}

export const addConsultationNotesReq = async({data})=>{
    const BODY = {
        "user_id": data?.guestId,
        "user_program_id": data?.userProgramId,
        "visible_status": data?.isVisible ? 1: 0,
        "notes_detail": data?.content,
        "notes_type": data?.noteType,
        "notes_doc": data?.docsPath,
        "date_time": data?.date + moment().format(" HH:mm:ss")
      }

    return axiosInstance.post(apiEndpoints.ADD_CONSULTATION_NOTES_API, { data: BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            }else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* addConsultationNotes({data}){
    try{
        let docsPath=[]
        for(let i=0; i<data.files.newFiles?.length; i++){
            let filePath = notesFilePath({fileName: data.files.newFiles[i].name});
            yield call(uploadImageOnFirebase, data.files.newFiles[i], filePath)
            docsPath.push(filePath)
        }
        data.docsPath=docsPath;
        let result = yield call(addConsultationNotesReq, {data})

        if(result.statusCode === 200){
            yield put({ type: types.ADD_CONSULTATION_NOTES_DATA_SUCCESS }); 
            window.history.back();
            notify('success', result.message);
        }else{
            yield put({ type: types.ADD_CONSULTATION_NOTES_DATA_FALIURE }); 
        }
    }catch(error){
        console.log(error)
        yield put({ type: types.ADD_CONSULTATION_NOTES_DATA_FALIURE }); 
    }
}

export const updateConsultationNotesReq = async({data})=>{
    const BODY = {
        "user_program_note_id": data?.noteId,
        "is_guest_visible": data?.isVisible ? 1: 0,
        "notes_detail": data?.content,
        "notes_doc": data?.docsPath,
        "date_time": moment().format("YYYY-MM-DD HH:mm:ss")
      }

    return axiosInstance.put(apiEndpoints.UPDATE_CONSULTATION_NOTES_API, { data: BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            }else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* updateConsultationNotes({data}){
    try{
        let docsPath=[...data.files.uploadedFiles]
        for(let i=0; i<data.files.newFiles?.length; i++){
            let filePath = notesFilePath({fileName: data.files.newFiles[i].name});
            yield call(uploadImageOnFirebase, data.files.newFiles[i], filePath)
            docsPath.push(filePath)
        }

        for(let i=0; i<data.files.removedUploadedFiles?.length; i++){
            let filePath = data.files.removedUploadedFiles[i];
            yield call(deleteImageFromFirebase, filePath)
        }

        data.docsPath=docsPath;
        let result = yield call(updateConsultationNotesReq, {data})

        if(result.statusCode === 200){
            yield put({ type: types.UPDATE_CONSULTATION_NOTES_DATA_SUCCESS }); 
            window.history.back();
            notify('success', result.message);
        }else{
            yield put({ type: types.UPDATE_CONSULTATION_NOTES_DATA_FALIURE }); 
        }
    }catch(error){
        console.log(error)
        yield put({ type: types.UPDATE_CONSULTATION_NOTES_DATA_FALIURE }); 
    }
}


export const getPreviousNoteReq = async({data})=>{   
    return axiosInstance.get(`${apiEndpoints.GET_PREVIOUS_CONSULTATION_NOTE_BY_TYPE_API}?notes_type=${data.noteType}&user_program_id=${data.userProgramId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);

            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            }else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* getPreviousNote({data}){
    try{
        let result = yield call(getPreviousNoteReq, {data})
        if(result.statusCode === 200){
            yield put({ type: types.GET_PREVIOUS_CONSULTATION_NOTE_BY_TYPE_SUCCESS, payload: result.data});  
        }else{
            yield put({ type: types.GET_PREVIOUS_CONSULTATION_NOTE_BY_TYPE_FALIURE});
        }
    }catch(error){
        yield put({ type: types.GET_PREVIOUS_CONSULTATION_NOTE_BY_TYPE_FALIURE, message: error.message });
    }
}

function* consultationNotesSaga(){
    yield takeLatest(types.GET_CONSULTATION_NOTES_DATA, getConsultationNotes);
    yield takeLatest(types.GET_NOTES_TYPE_LIST, getNotesType);
    yield takeLatest(types.DEL_CONSULTATION_NOTES_DATA, deleteConsultationNotes);
    yield takeLatest(types.UPDATE_CONSULTATION_NOTES_VISIBLE_STATUS, updateConsultationNotesVisibleStatus);
    yield takeLatest(types.ADD_CONSULTATION_NOTES_DATA, addConsultationNotes);
    yield takeLatest(types.UPDATE_CONSULTATION_NOTES_DATA, updateConsultationNotes);
    yield takeLatest(types.GET_PREVIOUS_CONSULTATION_NOTE_BY_TYPE, getPreviousNote);
}

export default consultationNotesSaga;