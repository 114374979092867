import React, { useCallback, useEffect, useState } from 'react'
import './AddNotes.scss'
import 'react-quill/dist/quill.snow.css';
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import EditorToolbar, { modules, formats }  from '../../../components/EditorToolbar/EditorToolbar'
import ReactQuill from 'react-quill';
import { CircularProgress, Tooltip } from '@mui/material';
import Select from "react-select";
import openEyeBtn from '../../../assets/images/eye-open.svg'
import closeEyeBtn from '../../../assets/images/eye-close.svg'
import { notify } from '../../../utils/notification';
import {addConsultationNotes, clearConsultationNotes, getNotesTypeList } from '../../../redux/actions/GuestDataActions/ConsultationNotesActions';
import ChangeVisibilityModal from '../../../components/ChangeVisibilityModal/ChangeVisibilityModal';
import UploadButton from '../../../components/UploadButton/UploadButton';
import { ADD, DEPARTURE, dynamicNotesType } from '../Forms/NoteFormsConstants';
import arrowImg from '../../../assets/images/arrow-up-white.svg'
import { getAnandaProgramsList } from '../../../redux/actions/ProgramActions/ProgramAction';



function AddNotes() {
  const location = useLocation();
  const { guestId, userProgramId , date , guestName} = location.state;
  const UserData = JSON.parse(localStorage.getItem('UserData'))

  const [noteType, setNoteType] = useState('Ayurveda Arrival Consultation')
  const [eye, setEye] = useState(false)
  const [content, setContent] = useState('')
  const [files, setFiles] = useState({newFiles: [], uploadedFiles:[]})
  const [visibilityModal, setVisibilityModal] =useState(false)
  const [actionOnSubmit, setActionOnSubmit] = useState(undefined)

  const dispatch = useDispatch()
  const navigate= useNavigate()
  const consultationNotesData = useSelector(state=> state.consultationNotesReducer)
  const permissions = JSON.parse(localStorage.getItem('UserData'))?.permissions;
  const canWriteNote = (permissions?.consultation_notes?.permissions || '').includes('write')
  const canWriteDietNote = (permissions?.dietary_notes?.permissions || '').includes('write')

  const handleProcedureContentChange = (content, delta, source, editor) => {
    editor.getHTML(); // rich text
    editor.getText(); // plain text
    editor.getLength(); // number of characters
    setContent(content);
  };

  const handleEye = ()=>{
    setEye(prev=> !prev);
    setVisibilityModal(false)
}

  const handleSubmit = async()=>{
    if(!content || !noteType){
      notify('warning', 'Please Fill all Fields')
    }else{
      await actionOnSubmit?.()
      dispatch(addConsultationNotes({
        guestId,
        userProgramId,
        isVisible: eye,
        content : dynamicNotesType.find(item => item.title === noteType?.label) ? JSON.stringify(content) : content,
        noteType: noteType?.label,
        files,
        date
      }))

    }
  }

  
  const scrollToTop = () => {
    const container = document.querySelector('.scrollable-fields');
    if (container) {
      container.scrollTo({
        top: 0,
        behavior: "smooth", // Smooth scrolling
      });
    }
  };

  const handleNoteChange=(e)=>{
    dispatch(clearConsultationNotes())
    let isJSONNote = dynamicNotesType.find(item => item.title === e?.label)
    setNoteType(prev=>{
      if(isJSONNote){
        if(isJSONNote.json["conducted_by"] !==undefined)
          isJSONNote.json["conducted_by"] = `${UserData.first_name} ${UserData.last_name}`

        if(isJSONNote.hideVisibilityIcon)
          setEye(true)
        else
          setEye(false)
        
        setContent((JSON.parse(JSON.stringify(isJSONNote.json))))
      }
      else
        setContent('')
      setFiles({newFiles: [], uploadedFiles:[]})
      return e;
    })
  }

  const handleBlur = useCallback(() => {
      setContent((prevText) => prevText);
  }, [content]);


  useEffect(()=>{
    dispatch(getNotesTypeList())
    dispatch(getAnandaProgramsList())
  }, [])


  return (
    <>
      <div className="add-notes-container">
        <div className="container h-100">
            <div className="add-notes-heading mt-4">
              Consultant Recommendations
            </div>

            <div className="pt-4 pb-5">
              <div className="add-notes-content">
                <div className="d-flex justify-content-between align-items-center p-3">
                  <div className="heading">Add Note</div>
                  <div className='d-flex align-items-center'>
                    <button className='cancel' type='button' onClick={()=> navigate(-1)}>Cancel</button>
                    <button className="edit-btn" type='submit' onClick={handleSubmit}>
                      {
                        consultationNotesData?.updateLoader ?
                        <CircularProgress sx={{'color': '#fff'}} size={20}/>
                        :'Add'
                      }
                    </button>
                  </div>
                </div>

                <div className="form p-3">
                  <div className="scrollable-fields">
                    {
                      (()=>{
                        let isJSONNote = dynamicNotesType.find(item => item.title === noteType?.label)
                        let uploadComponent = <div className="mb-5">
                          <UploadButton
                          name={noteType?.label?.replace(/\s+/g, '') || 'default'}
                          files={files}
                          setFiles={setFiles}
                        />
                        </div>
                        return (
                          <>
                            <div className="d-flex mb-3 justify-content-between border-bottom">
                              <div className='mb-3'>
                                <div className='title mb-2'>Select Note Type</div>
                                <Select
                                    className="name-select"
                                    classNamePrefix="select"
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    styles={{
                                      container: (provided,state) => ({
                                          ...provided,
                                          width: "360px",
                                          
                                        }),
                                    }}
                                    options={
                                      [
                                        ...(consultationNotesData?.notesType ? consultationNotesData?.notesType?.map(item=> ({
                                          label: item.title,
                                          value: item.title
                                        }))?.filter(item => item.label !=='Dietary Notes' && canWriteNote) : []),
                                        ...(canWriteDietNote ? [{label: 'Dietary Notes', value: 'Dietary Notes'}] : []),
                                      ]
                                    }
                                    placeholder='Select'
                                    onChange={handleNoteChange}
                                />
                              </div>
                              {
                                !isJSONNote?.hideVisibilityIcon &&<div className='visible-btn' onClick={()=>setVisibilityModal(true)}>
                                <img src={eye ? openEyeBtn : closeEyeBtn} alt="eye-btn" className="me-2"/>
                                  <span className="title">{eye ? 'Visible to Guest' : 'Hide from Guest'}</span>
                                </div>
                              }
                            </div>
                            {
                              isJSONNote?
                              React.createElement(isJSONNote.component, {
                                content, setContent, 
                                type: isJSONNote.type, 
                                guestId, 
                                userProgramId, 
                                formType: ADD,
                                uploadComponent,
                                guestName,
                                conductedBy:  `${UserData.first_name} ${UserData.last_name}`,
                                setActionOnSubmit
                              })
                              :
                              <div>
                                <EditorToolbar />
                                <ReactQuill
                                  theme="snow"
                                  value={content}
                                  onChange={handleProcedureContentChange}
                                  onBlur={handleBlur}
                                  placeholder='Enter Keywords ...'
                                  formats={formats}
                                  modules={modules}
                                />
                                {uploadComponent}
                              </div>
                            }
                          </>
                        )
                      })()
                    }
                  </div>    
                </div>
                    
              </div>
            </div>
          </div>
          <button onClick={scrollToTop} className="scroll-to-top">
            <Tooltip  title="Move to top">
              <img src={arrowImg} style={{width: '15px'}} alt="" />
            </Tooltip>
          </button>
          <ChangeVisibilityModal
            warnModal={visibilityModal} 
            setWarnModal={setVisibilityModal} 
            onYes={handleEye}
            hide={eye}
          />
        </div>
      </>
  )
}

export default AddNotes