import TextArea from '../../../../../components/TextArea/TextArea'
import { TextField } from '../../../../../components/TextField/TextField'
import './AddEditAyurvedaArrivalConsultation.scss'
import { ADD, AyurvedaArrivalConsultationField, AyurvedaArrivalConsultationJSON, PostStayConsultationJSON } from '../../NoteFormsConstants'
import DatePicker from '../../../../../components/DatePicker/DatePicker'
import moment from 'moment'
import {ReactComponent as RemoveBtn } from '../../../../../assets/images/delete.svg'
import targetIcon from '../../../../../assets/images/target.svg'
import { useEffect } from 'react'
import { getPreAssessmentData } from '../../../../../redux/actions/GuestDataActions/PrelimAssessmentActions'
import { useDispatch, useSelector } from 'react-redux'
import { YesNoComponent } from '../../../../../components/YesNoComponent/YesNoComponent'
import { parseJSON } from '../../../../../utils/helpers/universalFunctions'
import { ReactComponent as Add } from '../../../../../assets/images/prelimAdd.svg'
import { CircularProgress } from '@mui/material'
import { intensityOptions } from '../../../../../const/dropdownOptions'
import Select from 'react-select'

const Objectives = ({setKey, removeObjective, content, handleAddMore})=>{
    return (
        <div className="d-flex flex-wrap align-items-stretch justify-content-start" key={content?.data?.summary_assessment_treatment_plan?.objectives?.length}>
            {
                content?.data?.summary_assessment_treatment_plan?.objectives?.map((subitem, subindex)=> {
                    return (
                        <div className="objective-box p-2" key={subindex}>
                            <div className='border rounded'>
                                <div className="heading p-3 py-2 d-flex justify-content-between align-items-center">
                                    <div>
                                    <img src={targetIcon} className='me-2' width={'20px'} alt="" />{(subindex+1)?.toString()?.padStart(2, 0)}
                                    </div>
                                    <div className='remove-btn' onClick={()=>removeObjective(subindex)}>
                                        <RemoveBtn/>
                                    </div>
                                </div>
                                <div className="p-3">
                                {
                                    AyurvedaArrivalConsultationField?.objectives?.map((item, index)=>{
                                        return (
                                            <div className="data-row" key={index}>
                                                <div className="fw-semi-bold mt-2" style={{width: '260px'}}>{item.label}</div>
                                                <div className='data-input'>
                                                    <TextArea
                                                        placeholder={"Enter "}
                                                        onChange={value =>setKey(["summary_assessment_treatment_plan", "objectives", subindex, item.key], value)}
                                                        row={2}
                                                        value={content?.data?.summary_assessment_treatment_plan?.objectives?.[subindex]?.[item.key] || ''}/>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            
            
            <div className="p-2 w-50 "  onClick={handleAddMore}>
                <div className="add-more ">
                    Add More Objectives
                </div>
            </div>
        </div>
    )
}



export const AddEditAyurvedaArrivalConsultation = ({content, setContent, conductedBy, type,  guestId, userProgramId, formType, uploadComponent, setActionOnSubmit})=>{
    const dispatch = useDispatch()
    const prelimAssessment = useSelector(state=> state.prelimAssessmentReducer?.prelimAssessment?.prelim_assesment_data)
    const loading = useSelector(state=> state.prelimAssessmentReducer?.loading)

  const setKey = (keys, value)=>{
      setContent(prev=>{
        let updatedData = { ...prev}
        keys.reduce((acc, key, index) => {
            if (index === keys.length - 1) {
              acc[key] = value; 
            } else {
                if(Array.isArray(acc[key])){
                    acc[key] = [...acc[key] ]
                }else{
                    acc[key] = { ...acc[key] };
                }
            }
            return acc[key];
          }, updatedData.data);
        return updatedData;
      })
    }

    const removeObjective = (index)=>{
        setContent(prev=>{
            let updatedData = {...prev};
            updatedData.data.summary_assessment_treatment_plan.objectives.splice(index, 1);
            return updatedData
        })
    }
    
    const handleAddMore=()=>{
        setContent(prev=>{
            let updatedData = { ...prev}
            updatedData.data.summary_assessment_treatment_plan.objectives = [...updatedData.data.summary_assessment_treatment_plan.objectives, AyurvedaArrivalConsultationJSON.data.summary_assessment_treatment_plan.objectives[0]]
            return updatedData;
        })
    }

    const removeArrayItem = (key, index) => {
        setContent(prev => {
            const updatedData = {
                ...prev,
                data: {
                    ...prev.data,
                    current_health_complaints: {
                        ...prev.data.current_health_complaints,
                        [key]:  prev.data.current_health_complaints[key].filter((_, i) => i !== index)
                    }
                }
            };
            return updatedData;
        });
    }

    const handleAddMoreInArray=([key])=>{
        setContent(prev=>{
            let updatedData = { ...prev}
            updatedData.data.current_health_complaints[key] = [...updatedData.data.current_health_complaints[key], {
                "condition": "",
                "duration": "",
                "intensity": ""
            }]
            return updatedData;
        })
    }


    useEffect(()=>{
        if(formType===ADD){
            setContent(prev=>{
                let updatedData = {...prev};
                updatedData.date = moment().format("YYYY-MM-DD")
                if(prelimAssessment){
                    updatedData.data.current_health_complaints.is_current_health_complaint_physical= prelimAssessment.is_current_health_complaint_physical ? parseInt(prelimAssessment.is_current_health_complaint_physical) : 0
                    updatedData.data.current_health_complaints.health_complaint_keywords_physical= parseJSON(prelimAssessment.health_complaint_keywords_physical) || AyurvedaArrivalConsultationJSON.data.current_health_complaints.health_complaint_keywords_physical
                    updatedData.data.current_health_complaints.is_current_health_complaint_mental= prelimAssessment.is_current_health_complaint_mental  ? parseInt(prelimAssessment.is_current_health_complaint_mental) : 0
                    updatedData.data.current_health_complaints.health_complaint_keywords_mental= parseJSON(prelimAssessment.health_complaint_keywords_mental) || AyurvedaArrivalConsultationJSON.data.current_health_complaints.health_complaint_keywords_mental
                }
                return updatedData;
            })
        }
    }, [prelimAssessment])

    useEffect(()=>{
        if(formType===ADD && guestId && userProgramId){
            dispatch(getPreAssessmentData({guestId, userProgramId}))
        }
        setActionOnSubmit((prev)=>{
            return ()=>{
                setContent(prev=>{
                    let updatedData = {...prev};
                    if(!updatedData.data.current_health_complaints.is_current_health_complaint_physical)
                        updatedData.data.current_health_complaints.health_complaint_keywords_physical= []
                    if(!updatedData.data.current_health_complaints.is_current_health_complaint_mental)
                        updatedData.data.current_health_complaints.health_complaint_keywords_mental= []
                    return updatedData;
                })
            }
        })
    },[])


    return (
      <div className='ayurveda-arrival-consultation-form'>
        {
            loading ?
            <div className="loader mx-auto">
                <CircularProgress sx={{'color': '#74613C'}} size={20}/>
            </div>
            :
            <>
                <>
                <div className="d-flex py-2 mb-3 rounded justify-content-between">
                    <div className="d-flex align-items-center">
                        <div className="title text-secondary me-3">Conducted By: </div>
                        {/* <TextField
                            width={'300px'}
                            type={'text'}
                            placeholder='Enter name' 
                            value={content?.conducted_by || ''} 
                            onChange={e=>setContent( {...content, 'conducted_by' : e.target.value})}
                        />
                        */}
                        {conductedBy || '-'}
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="title text-secondary me-3">Date</div>
                        <DatePicker
                        value={content.date ? new Date(content.date) : ''}
                        onChange={date=>setContent( {...content, 'date' : moment(date).format('YYYY-MM-DD')})}
                        width={'300px'}
                        />
                    </div>
                    </div>
                    <div className="border w-100 mb-5 rounded current-dietary-pattern ">
                        <div className="heading py-3">
                        CURRENT HEALTH COMPLAINTS
                        </div> 
                        <div className='p-3'>
                            <div className="d-flex justify-content-between">
                                <div className='fw-semibold pb-4 mb-2'>
                                    Current health Complaints (Physical)
                                </div>
                                <YesNoComponent
                                    name="is_current_health_complaint_physical"
                                    handleClick={value =>setKey(["current_health_complaints", "is_current_health_complaint_physical"], value)}
                                    value={content.data?.current_health_complaints.is_current_health_complaint_physical}
                                />
                            </div>
                            {
                                content.data.current_health_complaints.is_current_health_complaint_physical === 1 
                                &&
                                <div>
                                    <div className="row m-0 fw-semibold py-2 border-top border-bottom mb-3">
                                        <div className="col-5">Condition</div>
                                        <div className="col-3">Intensity</div>
                                        <div className="col-4">Duration</div>
                                    </div>
                                    <div>
                                    {
                                        content?.data.current_health_complaints.health_complaint_keywords_physical?.map((item, index)=>{
                                            return (
                                                <div className="row" key={index}>
                                                    <div className="col-5">
                                                        <TextArea
                                                            row={3}
                                                            placeholder={"Enter "}
                                                            onChange={value =>setKey(["current_health_complaints", "health_complaint_keywords_physical", index, 'condition'], value)}
                                                            value={content?.data?.current_health_complaints?.health_complaint_keywords_physical?.[index]?.['condition'] || ''}/>
                                                    </div>
                                                    <div className="col-3 pt-1">
                                                        <Select
                                                            className="name-select"
                                                            classNamePrefix="select"
                                                            // isLoading={isLoading}
                                                            isSearchable={true}
                                                            name="color"
                                                            options={intensityOptions}
                                                            placeholder='Select Intensity'
                                                            onChange={e =>setKey(["current_health_complaints", "health_complaint_keywords_physical", index, 'intensity'], e.value)}
                                                            value={
                                                                content?.data?.current_health_complaints?.health_complaint_keywords_physical?.[index]?.['intensity'] ?
                                                                {
                                                                    label: content?.data?.current_health_complaints?.health_complaint_keywords_physical?.[index]?.['intensity'],
                                                                    value: content?.data?.current_health_complaints?.health_complaint_keywords_physical?.[index]?.['intensity']
                                                                }
                                                                :
                                                                ''
                                                            }  
                                                        />
                                                    </div>
                                                    <div className="col-4 d-flex align-items-center">
                                                        <div className="flex-grow-1">
                                                        <TextArea
                                                            row={3}
                                                            placeholder={"Enter "}
                                                            onChange={value =>setKey(["current_health_complaints", "health_complaint_keywords_physical", index, 'duration'], value)}
                                                            value={content?.data?.current_health_complaints?.health_complaint_keywords_physical?.[index]?.['duration'] || ''} />
                                                        </div>
                                                        {
                                                            content?.data.current_health_complaints.health_complaint_keywords_physical?.length >1
                                                            &&
                                                            <div className='remove-btn ms-2' onClick={()=>removeArrayItem("health_complaint_keywords_physical", index)}>
                                                                <RemoveBtn/>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div>
                                        <div className='cursor-pointer mt-2 mx-auto' style={{width: 'fit-content'}} onClick={()=>handleAddMoreInArray(["health_complaint_keywords_physical"])}>
                                            <Add/>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='p-3 pt-0' key={content?.data.current_health_complaints.is_current_health_complaint_mental}>
                            <div className="d-flex justify-content-between">
                                <div className='fw-semibold pb-4 mb-2'>
                                    Current health Complaints (Mental)
                                </div>
                                <YesNoComponent
                                    name="is_current_health_complaint_mental"
                                    handleClick={value =>setKey(["current_health_complaints", "is_current_health_complaint_mental"], value)}
                                    value={content.data?.current_health_complaints.is_current_health_complaint_mental}
                                />
                            </div>
                            {
                                content.data?.current_health_complaints.is_current_health_complaint_mental === 1 
                                &&
                                <div>
                                    <div className="row m-0 fw-semibold py-2 border-top border-bottom mb-3">
                                        <div className="col-5">Condition</div>
                                        <div className="col-3">Intensity</div>
                                        <div className="col-4">Duration</div>
                                    </div>
                                    <div key={content?.data.current_health_complaints.health_complaint_keywords_mental?.length}>
                                    {
                                        content?.data.current_health_complaints.health_complaint_keywords_mental?.map((item, index)=>{
                                            return (
                                                <div className="row m-0" key={index}>
                                                    <div className="col-5">
                                                        <TextArea
                                                            row={3}
                                                            placeholder={"Enter "}
                                                            onChange={value =>setKey(["current_health_complaints", "health_complaint_keywords_mental", index, 'condition'], value)}
                                                            value={content?.data?.current_health_complaints?.health_complaint_keywords_mental?.[index]?.['condition'] || ''}/>
                                                    </div>
                                                    <div className="col-3 p-1">
                                                            <Select
                                                                styles={{
                                                                    container: (provided,state) => ({
                                                                        ...provided,
                                                                        width: "100% !important",
                                                                        
                                                                      }),
                                                                }}
                                                                className="name-select"
                                                                classNamePrefix="select"
                                                                // isLoading={isLoading}
                                                                isSearchable={true}
                                                                name="color"
                                                                options={intensityOptions}
                                                                placeholder='Select Intensity'
                                                                onChange={e =>setKey(["current_health_complaints", "health_complaint_keywords_mental", index, 'intensity'], e.value)}
                                                                value={
                                                                    content?.data?.current_health_complaints?.health_complaint_keywords_mental?.[index]?.['intensity'] ?
                                                                    {
                                                                        label: content?.data?.current_health_complaints?.health_complaint_keywords_mental?.[index]?.['intensity'],
                                                                        value: content?.data?.current_health_complaints?.health_complaint_keywords_mental?.[index]?.['intensity']
                                                                    }
                                                                    :
                                                                    ''
                                                                }                                                            />
                                                    </div>
                                                    <div className="col-4 d-flex align-items-center">
                                                        <div className="flex-grow-1">
                                                        <TextArea
                                                            row={3}
                                                            placeholder={"Enter "}
                                                            onChange={value =>setKey(["current_health_complaints", "health_complaint_keywords_mental", index, 'duration'], value)}
                                                            value={content?.data?.current_health_complaints?.health_complaint_keywords_mental?.[index]?.['duration'] || ''} />
                                                        </div>
                                                        {
                                                            content?.data.current_health_complaints.health_complaint_keywords_mental?.length >1
                                                            &&
                                                            <div className='remove-btn ms-2' onClick={()=>removeArrayItem("health_complaint_keywords_mental", index)}>
                                                                <RemoveBtn/>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div>
                                        <div className='cursor-pointer mt-2 mx-auto' style={{width: 'fit-content'}} onClick={()=>handleAddMoreInArray(["health_complaint_keywords_mental"])}>
                                            <Add/>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="border w-100 rounded current-dietary-pattern ">
                        <div className="heading py-3">
                            CURRENT DIETARY PATTERN
                        </div>
                        <div className='p-3'>
                            <div className="sub-heading mb-3">
                                Intake:
                            </div>
                            <div className='grid row'>
                                {
                                    AyurvedaArrivalConsultationField?.currentDietaryPattern?.intake?.map((item, index)=>{
                                        return (
                                            <div className="data-row col-6" key={index}>
                                                <div className="title mt-3">{item.label}</div>
                                                <div className='data-input'>
                                                    <TextArea
                                                        row={3}
                                                        placeholder={"Enter "}
                                                        onChange={value =>setKey(["current_dietary_pattern", "intake", item.key], value)}
                                                        value={content?.data?.current_dietary_pattern?.intake?.[item.key] || ''}/>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className='p-3'>
                            <div className="sub-heading text-uppercase mb-3">
                                Additional Information:
                            </div>
                            <div className='grid row'>
                                {
                                    AyurvedaArrivalConsultationField?.currentDietaryPattern?.additionalInfo?.map((item, index)=>{
                                        return (
                                            <div className="data-row col-6" key={index}>
                                                <div className="title mt-3">{item.label}</div>
                                                <div className='data-input'>
                                                    <TextArea
                                                        row={3}
                                                        placeholder={"Enter "}
                                                        onChange={value =>setKey(["current_dietary_pattern", "additional_info", item.key], value)}
                                                        value={content?.data?.current_dietary_pattern?.additional_info?.[item.key] || ''}/>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="border w-100 mt-5 rounded healthassessment">
                        <div className="heading py-3">
                            AYURVEDIC HEALTH ASSESSMENT
                        </div>
                        <div className='p-3'>
                                {
                                    AyurvedaArrivalConsultationField?.healthAssessment?.map((item, index)=>{
                                        return (
                                            <div className="data-row" key={index}>
                                                <div className="title mt-3">{item.label}</div>
                                                <div className='data-input'>
                                                    <TextArea
                                                        row={3}
                                                        placeholder={"Remarks "}
                                                        onChange={value =>setKey(["ayurvedic_health_assessment", item.key], value)}
                                                        value={content?.data?.ayurvedic_health_assessment?.[item.key] || ''}/>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                        </div>
                    </div>
                    <div className="border w-100 mt-5 rounded systematic">
                        <div className="heading py-3">
                            SYSTEMIC  ASSESSMENT
                        </div>
                        <div className='p-3'>
                            <div className="sub-heading my-2">
                                Respiratory System:
                            </div>
                            <div className='py-3'>
                                <div className="data-row">
                                    <div className="title mt-2 pt-1">Lung Field: </div>
                                    <div className='data-input'>
                                        <TextArea
                                            row={3}
                                            placeholder={"Remarks "}
                                            onChange={value =>setKey(["systemic_assessment", "respiratory_system", "lung_field"], value)}
                                            value={content?.data?.systemic_assessment?.respiratory_system?.["lung_field"] || ''}/>
                                    </div>
                                </div>
                                <div className="data-row">
                                    <div className="title mt-2 pt-1">Auscultation: </div>
                                    <div className='data-input'>
                                        <TextArea
                                            row={3}
                                            placeholder={"Remarks "}
                                            onChange={value =>setKey(["systemic_assessment", "respiratory_system", "auscultation"], value)}
                                            value={content?.data?.systemic_assessment?.respiratory_system?.["auscultation"] || ''}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='p-3'>
                            <div className="sub-heading">
                            Cardiovascular System:
                            </div>
                            <div className='py-3'>
                                <div className="data-row mb-3">
                                    <div className="title mt-1">Pluse Rate: </div>
                                    <div className='data-input  w-50'>
                                        <TextField 
                                            type={'item.type'}
                                            placeholder='Enter value' 
                                            value={content?.data?.systemic_assessment?.cardiovascular_system?.pulse_rate?.value || ''} 
                                            onChange={e =>setKey(["systemic_assessment", "cardiovascular_system", "pulse_rate", "value"], e.target.value)}
                                            unit={content.data?.systemic_assessment?.cardiovascular_system?.pulse_rate?.unit}
                                            />

                                    </div>
                                </div>
                                <div className="data-row mb-3">
                                    <div className="title mt-1">Blood Pressure: </div>
                                    <div className='data-input  w-50'>
                                        <TextField 
                                            type={'item.type'}
                                            placeholder='Enter value' 
                                            value={content?.data?.systemic_assessment?.cardiovascular_system?.blood_pressure?.value || ''} 
                                            onChange={e =>setKey(["systemic_assessment", "cardiovascular_system", "blood_pressure", "value"], e.target.value)}
                                            unit={content.data?.systemic_assessment?.cardiovascular_system?.blood_pressure?.unit}
                                            />

                                    </div>
                                </div>
                                <div className="data-row mb-3">
                                    <div className="title mt-1">SpO<sub>2</sub></div>
                                    <div className='data-input w-50'>
                                        <TextField 
                                            type={'item.type'}
                                            placeholder='Enter value' 
                                            value={content?.data?.systemic_assessment?.cardiovascular_system?.spo2?.value || ''} 
                                            onChange={e =>setKey(["systemic_assessment", "cardiovascular_system", "spo2", "value"], e.target.value)}
                                            unit={content.data?.systemic_assessment?.cardiovascular_system?.spo2?.unit}
                                            />

                                    </div>
                                </div>
                                <TextArea
                                    row={3}
                                    placeholder={"Remarks "}
                                    onChange={value =>setKey(["systemic_assessment", "cardiovascular_system", "remarks"], value)}
                                    value={content?.data?.systemic_assessment?.cardiovascular_system?.remarks || ''}
                                />
                            </div>
                        </div>
                        <div className='px-3'>
                            <div className="sub-heading">
                                Musculoskeletal system:
                            </div>
                            <div className='text-muted'>
                                Region, Pain, tenderness, ROM
                            </div>
                            <div className='py-2'>
                                <TextArea
                                    row={3}
                                    placeholder={"Enter "}
                                    onChange={value =>setKey(["systemic_assessment", "musculoskeletal_system"], value)}
                                    value={content?.data?.systemic_assessment?.musculoskeletal_system || ''}
                                />
                            </div>
                        </div>
                        <div className='px-3'>
                            <div className="sub-heading">
                                Skin:
                            </div>
                            <div className='py-2'>
                                <TextArea
                                    row={3}
                                    placeholder={"Enter "}
                                    onChange={value =>setKey(["systemic_assessment", "skin"], value)}
                                    value={content?.data?.systemic_assessment?.skin || ''}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="border w-100 rounded mt-5 ashtavidha">
                        <div className="heading py-3">
                        ASHTAVIDHA PARIKSHA
                        </div>
                        <div className='p-3'>
                            {
                                AyurvedaArrivalConsultationField?.ashthavidhaPariksha?.map((item, index)=>{
                                    return (
                                        <div className="data-row" key={index}>
                                            <div className="title mt-2">{item.label}</div>
                                            <div className='data-input'>
                                                <TextArea
                                                    row={3}
                                                    placeholder={"Enter "}
                                                    onChange={value =>setKey(["ashthavidha_pariksha", item.key], value)}
                                                    value={content?.data?.ashthavidha_pariksha?.[item.key] || ''}/>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="border w-100 rounded mt-5">
                        <div className="heading py-3">
                            LAB REPORTS/ OTHER OBSERVATIONS
                        </div>
                        <div className='p-3'>
                            <TextArea
                                placeholder={"Enter "}
                                onChange={value =>setKey(["lab_reports"], value)}
                                value={content?.data?.lab_reports || ''}/>
                        </div>
                    </div>
                    <div className="border w-100 rounded mt-5 summary">
                        <div className="heading py-3">
                            SUMMARY ASSESSMENT AND TREATMENT PLAN
                        </div>
                        <div className='p-3'>
                            <div className="data-row">
                                <div className="title mt-2">Dosha Prakriti: </div>
                                <div className='data-input'>
                                    <TextArea
                                        row={3}
                                        placeholder={"Remarks "}
                                        onChange={value =>setKey(["summary_assessment_treatment_plan", "dosha_prakriti"], value)}
                                        value={content?.data?.summary_assessment_treatment_plan?.["dosha_prakriti"] || ''}/>
                                </div>
                            </div>
                            <div className="data-row">
                                <div className="title mt-2">Dosha Vikruti (to be balanced): </div>
                                <div className='data-input'>
                                    <TextArea
                                        row={3}
                                        placeholder={"Remarks "}
                                        onChange={value =>setKey(["summary_assessment_treatment_plan", "dosha_vikruiti"], value)}
                                        value={content?.data?.summary_assessment_treatment_plan?.["dosha_vikruiti"] || ''}/>
                                </div>
                            </div>
                            <div className="sub-heading py-4">
                                Primary objectives for improvement
                            </div>
                            <Objectives
                                content={content}
                                removeObjective={removeObjective}
                                setKey={setKey}
                                handleAddMore={handleAddMore}
                            />
                        </div>

                    </div>
                </>
                <div className="w-50">
                {uploadComponent}
                </div>
            </>
        }
      </div>
    )
}