import React, {memo, useEffect, useState } from 'react'
import './TextArea.scss'

function TextArea({name, value, placeholder, fullHeight, heading, disabled=false, onChange, row}) {
  const [text, setText] = useState(value)

  useEffect(() => {
    if(value?.length)
      onChange?.(value)
  }, [])

  useEffect(()=>{
    if(value)
    setText(value)
  }, [value])


  return (
    <div className='text-area h-100'>
      {heading && <div className="heading my-2 mt-3 h-100">{heading}</div>}
      <div className={`${fullHeight ? 'h-100': ''} input`}>
        <textarea 
        disabled={disabled}
        className={`${fullHeight ? 'h-100': ''}`}
          name={name} 
          placeholder={disabled ? "-" : placeholder}
          onChange={(e)=>{
            setText(e.target.value);
            onChange(e.target.value)
          }}
          value={text}
          {...(row && {rows: row})}
        >
        
        </textarea>
      </div>
    </div>
  )
}

export default memo(TextArea);