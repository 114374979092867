import moment from 'moment'
import { DEPARTURE, LabTestsField } from '../NoteFormsConstants'
  

export const ViewLabTests = ({content, type, conductedBy})=>{
    return (
        <div className="mt-3">
            <div className="rounded border d-flex justify-content-between bg-light p-2 mb-2">
                <div className='d-flex'>
                    <div className="fw-semi-bold mr-2 flex-shrink-0 me-3" >Conducted By:</div>
                    <div className="text-break white-space">{conductedBy || '-'}</div>
                </div>
                <div className='d-flex  w-25'>
                    <div className="fw-semi-bold mr-2 flex-shrink-0 me-3" >Date:</div>
                    <div className="text-break white-space">{content.date? moment(content.date).format('DD MMM, YYYY') : '-'}</div>
                </div>
            </div>
            {
                type===DEPARTURE ?
                <div className='mb-3 border p-2 rounded'>
                    <div className="row m-0 fw-semi-bold mb-3 pb-2 border-bottom text-uppercase">
                        <div className="col-4"></div>
                        <div className="col-4 text-brown">Arrival Data</div>
                        <div className="col-4 text-brown">Departure Data</div>
                    </div>
                    {
                        LabTestsField?.map((item, index)=>(
                            <div className='row mb-3' key={index}>
                                <div className="col-4 fw-semi-bold ">{item?.label}:</div>
                                <div className="col-4 text-break white-space">{content.data[item.key].arrival_reading ? `${content.data[item.key].arrival_reading || '-'}` : '-'}</div>
                                <div className="col-4 text-break white-space">{content.data[item.key].value ? `${content.data[item.key].value || '-'}` : '-'}</div>
                            </div>
                            ))
                    }
                </div>
                :
                <div className='mb-3 border p-2 rounded'>
                    {
                        LabTestsField?.map((item, index)=>(
                            <div className='d-flex mb-3' key={index}>
                                <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>{item?.label}:</div>
                                <div className="text-break white-space">{content.data[item.key].value ? `${content.data[item.key].value}` : '-'}</div>
                            </div>
                            ))
                    }
                </div>
            }
        </div>
    )
}
