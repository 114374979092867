import { call, put, takeEvery } from "redux-saga/effects";
import axiosInstance from "./../../../axios/axiosInstance";
import { notify } from "./../../../utils/notification";
import {logOut} from '../../../utils/helpers/logout'
import * as types from "../../constants";
import apiEndpoints from "../../../axios/api";
import { deleteImageFromFirebase, getFirebaseURL, uploadImageOnFirebase } from "../../../firebase/firebaseFunction";
import { notesFilePath } from "../../../firebase/getFirebasePath";



export const getPrelimAssessmentReq = async({data})=>{
    return axiosInstance.get(`${apiEndpoints.GET_PRELIM_ASSESSMENT_API}?user_id=${parseInt(data.guestId)}&user_program_id=${parseInt(data.userProgramId)}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* getPrelimAssessment({data}){
    try{
        yield put({ type: types.PRE_ASSESSMENT_LOADING })
        let result = yield call(getPrelimAssessmentReq, {data})
        result.data.prelim_assesment_data["url"] = []
        if(result.statusCode === 200){
            if(result?.data?.prelim_assesment_data?.docs)
                for(let item of result?.data?.prelim_assesment_data?.docs){
                    result.data.prelim_assesment_data.url.push(yield getFirebaseURL(item))
                }
            

            yield put({ type: types.GET_PRE_ASSESSMENT_DATA_SUCCESS, payload: result.data});
        }else{
            yield put({ type: types.GET_PRE_ASSESSMENT_DATA_FALIURE }); 
        }    
    }catch(error){
        console.log(error)
        yield put({ type: types.GET_PRE_ASSESSMENT_DATA_FALIURE }); 
    }
}


export const deletePrelimAssessmentReq = async({data})=>{
    const BODY = {
        "prelim_assessment_id": data.prelim_assessment_id
      }

    return axiosInstance.delete(apiEndpoints.DELETE_PRELIM_ASSESSMENT_API, { data: {data: BODY} })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* deletePrelimAssessment({data}){
    try{
        yield put({ type: types.PRE_ASSESSMENT_LOADING })
        let result = yield call(deletePrelimAssessmentReq, {data})

        if(result.statusCode === 200){
            yield put({ type: types.DEL_PRE_ASSESSMENT_DATA_SUCCESS});
            yield put({ type: types.UPDATE_GUEST_LIST, payload: {type: data.type, user_program_id: data.user_program_id, value: 1, keyName: "is_prelim_assessment_completed"}});
            notify("success", result.message)
        }else{
            yield put({ type: types.DEL_PRE_ASSESSMENT_DATA_FALIURE }); 
        }    
    }catch(error){
        console.log(error)
    }
}

export const addPrelimAssessmentReq = async({data})=>{
    const BODY = {
        "user_id": data.user_id,
        "user_program_id": data.user_program_id,
        "user_height": parseFloat(data.user_height),
        "user_weight": parseFloat(data.user_weight),
        "objectives": data.objectives,
        "is_current_health_complaint_physical": parseInt(data.is_current_health_complaint_physical),
        "health_complaint_keywords_physical": JSON.stringify(data.health_complaint_keywords_physical),
        "is_current_health_complaint_mental": parseInt(data.is_current_health_complaint_mental),
        "health_complaint_keywords_mental": JSON.stringify(data.health_complaint_keywords_mental),
        "is_past_medical_history": parseInt(data.is_past_medical_history),
        "past_medical_history": JSON.stringify(data.past_medical_history),
        "is_current_medications": parseInt(data.is_current_medications),
        "current_medications": data.current_medications,
        "is_allergies": parseInt(data.is_allergies),
        "allergies_keywords": data.allergies_keywords,
        "family_history": data.family_history,
        "diet": data.diet,
        "recommended_stay_duration": data.recommended_stay_duration,
        "recommended_program": data.recommended_program,
        "medical_reports_requested_for": data.medical_reports_requested_for,
        "specific_request_from_guest": data.specific_request_from_guest,
        "docs": data.docsPath
      }

      return axiosInstance.post(apiEndpoints.ADD_PRELIM_ASSESSMENT_API, { data: BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* addPrelimAssessment({data}){
    try{
        let docsPath=[]
        for(let i=0; i<data.files.newFiles?.length; i++){
            let filePath = notesFilePath({fileName: data.files.newFiles[i].name});
            yield call(uploadImageOnFirebase, data.files.newFiles[i], filePath)
            docsPath.push(filePath)
        }
        data.docsPath=docsPath;

        let result = yield call(addPrelimAssessmentReq, {data})

        if(result.statusCode === 200){
            window.history.back();
            yield put({ type: types.UPDATE_GUEST_LIST, payload: {type: data.type, user_program_id: data.user_program_id, value: 2, keyName: "is_prelim_assessment_completed"}});
            notify('success', result.message);
        }
        yield put({ type: types.ADD_PRE_ASSESSMENT_DATA_FALIURE});

    }catch(error){
        yield put({ type: types.ADD_PRE_ASSESSMENT_DATA_FALIURE});

        console.log(error)
    }
}


export const updatePrelimAssessmentReq = async({data})=>{
    const BODY = {
        "id": data.id,
        "user_height": parseFloat(data.user_height),
        "user_weight": parseFloat(data.user_weight),
        "objectives": data.objectives,
        "is_current_health_complaint_physical": parseInt(data.is_current_health_complaint_physical),
        "health_complaint_keywords_physical": JSON.stringify(data.health_complaint_keywords_physical),
        "is_current_health_complaint_mental": parseInt(data.is_current_health_complaint_mental),
        "health_complaint_keywords_mental": JSON.stringify(data.health_complaint_keywords_mental),
        "is_past_medical_history": parseInt(data.is_past_medical_history),
        "past_medical_history": JSON.stringify(data.past_medical_history),
        "is_current_medications": parseInt(data.is_current_medications),
        "current_medications": data.current_medications,
        "is_allergies": parseInt(data.is_allergies),
        "allergies_keywords": data.allergies_keywords,
        "family_history": data.family_history,
        "diet": data.diet,
        "recommended_stay_duration": data.recommended_stay_duration,
        "recommended_program": data.recommended_program,
        "medical_reports_requested_for": data.medical_reports_requested_for,
        "specific_request_from_guest": data.specific_request_from_guest,
        "docs": data?.docsPath,
      }

    return axiosInstance.put(apiEndpoints.UPDATE_PRELIM_ASSESSMENT_API, { data: BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            }else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* updatePrelimAssessment({data}){
    try{
        let docsPath=[...data.files.uploadedFiles]
        for(let i=0; i<data.files.newFiles?.length; i++){
            let filePath = notesFilePath({fileName: data.files.newFiles[i].name});
            yield call(uploadImageOnFirebase, data.files.newFiles[i], filePath)
            docsPath.push(filePath)
        }

        for(let i=0; i<data.files.removedUploadedFiles?.length; i++){
            let filePath = data.files.removedUploadedFiles[i];
            yield call(deleteImageFromFirebase, filePath)
        }

        data.docsPath=docsPath;

        let result = yield call(updatePrelimAssessmentReq, {data})

        if(result.statusCode === 200){
            window.history.back();
            notify('success', result.message);
        }    
        yield put({ type: types.UPDATE_PRE_ASSESSMENT_DATA_SUCCESS});

    }catch(error){
        console.log(error)
        yield put({ type: types.UPDATE_PRE_ASSESSMENT_DATA_FALIURE }); 
    }
}


export async function downloadPreArrivalConsultationReq(action) {
    return axiosInstance.get(`${apiEndpoints.DOWNLOAD_PRE_ARRIVAL_CONSULTATION_PDF_API}?user_id=${action.data?.guestId}&user_program_id=${action.data?.userProgramId}${action.data?.user_self_assessment_id? `&user_self_assessment_id=${action.data.user_self_assessment_id}`: ''}`, {
        responseType: 'arraybuffer',
    })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* downloadPreArrivalConsultation(action) {
    try {
        const result = yield call(downloadPreArrivalConsultationReq, action);
        if (result.data instanceof ArrayBuffer) {
            action.data?.onSuccessCallback?.(result.data)
            yield put({ type: types.DOWNLOAD_PRE_ARRIVAL_CONSULTATION_PDF_SUCCESS }); 

        }else{
            notify('Unable to download sheet.')
            yield put({ type: types.DOWNLOAD_PRE_ARRIVAL_CONSULTATION_PDF_FALIURE }); 

        }
    } catch (e) {
        yield put({ type: types.DOWNLOAD_PRE_ARRIVAL_CONSULTATION_PDF_FALIURE }); 

    }
}

function* prelimAssessmentSaga(){
    yield takeEvery(types.GET_PRE_ASSESSMENT, getPrelimAssessment)
    yield takeEvery(types.DEL_PRE_ASSESSMENT, deletePrelimAssessment)
    yield takeEvery(types.ADD_PRE_ASSESSMENT, addPrelimAssessment)
    yield takeEvery(types.UPDATE_PRE_ASSESSMENT, updatePrelimAssessment)
    yield takeEvery(types.DOWNLOAD_PRE_ARRIVAL_CONSULTATION_PDF, downloadPreArrivalConsultation)
}

export default prelimAssessmentSaga;