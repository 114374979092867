import React, { useEffect } from 'react'
import './Testimonial.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { viewTestimonial } from '../../redux/actions/GuestDataActions/TestimonialActions'
import GuestDetailsHeader from '../../components/GuestDetailsHeader/GuestDetailsHeader'
import moment from 'moment'

function Testimonial() {
    const {loading, guestDetails} = useSelector(state=> state.guestDetailsReducer)
    const testimonialReducer = useSelector(state=> state.testimonialReducer)
    const location = useLocation();
    const dispatch = useDispatch();
    const {userProgramId, guestId} = location.state;

    useEffect(()=>{
        dispatch(viewTestimonial({userProgramId}))
    }, [])


  return (
    <div>
        <div className="testimonial-container">
            <div className="container h-100">
                <div className="testimonial-heading">
                    Testimonial
                </div>           
                {   (testimonialReducer.loading || loading) && (guestId !== guestDetails?.user_id) ?
                        <div className="loader">
                            <CircularProgress sx={{'color': '#74613C'}}/>
                        </div>

                        :

                    <div className="pt-4 pb-5">
                        <div className="testimonial-content">
                            <GuestDetailsHeader guestId={guestId} />
                            <div className="p-3">
                                <div className='d-flex mb-3'>
                                    <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '150px'}}>Details:</div>
                                    <div className="text-break white-space">{testimonialReducer.testimonialData?.review_text || '-'} </div>
                                </div>
                                <div className='d-flex mb-3'>
                                    <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '150px'}}>Submitted On:</div>
                                    <div className="text-break white-space">{moment(testimonialReducer.testimonialData?.creation_datetime).format("DD/MM/YYYY") || '-'}</div>
                                </div>
                                <div className='d-flex mb-3'>
                                    <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '150px'}}>Status:</div>
                                    <div className={`${testimonialReducer?.testimonialData?.status==='approved' ? 'text-success' : (testimonialReducer?.testimonialData?.status==='pending' ? 'text-warning' : 'text-danger')} fw-semi-bold text-break white-space text-capitalize`}>{testimonialReducer?.testimonialData?.status || '-'}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
  )
}

export default Testimonial