import { AddEditAyurvedaArrivalConsultation } from "./AyurvedaArrivalConsultation/AddEditAyurvedaArrivalConsultation/AddEditAyurvedaArrivalConsultation";
import { AddEditDietaryNotes } from "./DietaryNotes/AddEditDietaryNotes/AddEditDietaryNotes";
import { AddEditLabTests } from "./LabTests/AddEditLabTests/AddEditLabTests";
import { AddEditVitalsCheck } from "./VitalsCheck/AddEditVitalsCheck/AddEditVitalsCheck";
import { AddEditYogaArrivalConsultation } from "./YogaArrivalConsultation/AddEditYogaArrivalConsultation/AddEditYogaArrivalConsultation";
import { AddEditTCMArrivalConsultation } from "./TCMArrivalConsultation/AddEditTCMArrivalConsultation/AddEditTCMArrivalConsultation";
import { AddEditPhysioArrivalConsultation } from "./PhysioArrivalConsultation/AddEditPhysioArrivalConsultation/AddEditPhysioArrivalConsultation";
import { AddEditAyurvedaDepartureConsultation } from "./AyurvedaDepartureConsultation/AddEditAyurvedaDepartureConsultation/AddEditAyurvedaDepartureConsultation";
import { AddEditYogaDepartureConsultation } from "./YogaDepartureConsultation/AddEditYogaDepartureConsultation/AddEditYogaDepartureConsultation";
import { AddEditTCMDepartureConsultation } from "./TCMDepartureConsultation/AddEditTCMDepartureConsultation/AddEditTCMDepartureConsultation";
import { AddEditPhysioDepartureConsultation } from "./PhysioDepartureConsultation/AddEditPhysioDepartureConsultation/AddEditPhysioDepartureConsultation";
import { AddEditPostStayConsultation } from "./PostStayConsultation/AddEditPostStayConsultation/AddEditPostStayConsultation";

export const DEPARTURE='departure';
export const ADD='add';
export const EDIT='edit';

export const DietrayNoteField = [
    {label: "Detox Diet Days", key: 'detox_diet_days', type: 'input'},
    {label: "Liquid Diet Days", key: 'liquid_diet_days', type: 'input'},
    {label: "Recommended Diet", key: 'recommended_diet'},
    {label: "Allergies", key: 'allergies'},
    {label: "General Diet Information", key: 'general_diet_information'},
    {label: "General Beverage Information", key: 'general_beverage_information'},
  ]

export const DietrayNoteJSON = {
  "detox_diet_days": "",
  "liquid_diet_days": "",
  "recommended_diet": "",
  "allergies": "",
  "general_diet_information": "",
  "general_beverage_information": ""
}

export const VitalsCheckField = [
  {label: "Blood Pressure", key: 'blood_pressure', unit: 'mm Hg', machine: "BP", type: 'text'},
  {label: "Pulmonary Function", key: 'pulmonary_function', unit: 'mL', machine: "SPIROMETER", type: 'numeric'},
  {label: "Weight", key: 'weight', unit: 'Kg', machine: "BMI", type: 'numeric'},
  {label: "Skeletal Muscle Mass (SMM)", key: 'smm', unit: 'Kg', machine: "BMI", type: 'numeric'},
  {label: "Body Fat", key: 'body_fat', unit: 'Kg', machine: "BMI", type: 'numeric'},
  {label: "Percentage Body Fat (PBF)", key: 'pbf', unit: '%', machine: "BMI", type: 'numeric'},
  {label: "Body Mass Index", key: 'bmi', unit: 'kg/m2', machine: "BMI", type: 'numeric'},
  {label: "Oxygen Saturation", key: 'oxygen_saturation', unit: 'spO2', machine: "OXYMETER", type: 'numeric'},
  {label: "Heart Rate (Resting)", key: 'heart_rate', unit: 'BPM', machine: "OXYMETER", type: 'numeric'},
]

export const VitalsCheckJSON = {
  "data": {
    "blood_pressure": { value: "", unit: "mm Hg" },
    "pulmonary_function": { value: "", unit: "mL" },
    "weight": { value: "", unit: "Kg" },
    "smm": { value: "", unit: "Kg" },
    "body_fat": { value: "", unit: "Kg" },
    "pbf": { value: "", unit: "%" },
    "bmi": { value: "", unit: "kg/m2" },
    "oxygen_saturation": { value: "", unit: "spO2" },
    "heart_rate": { value: "", unit: "BPM" }
  },
  "conducted_by": "",
  "date": ""
}

export const LabTestsField = [
  {label: "Lipid Profile", key: 'lipid_profile'},
  {label: "Thyroid Profile", key: 'thyroid_profile'},
  {label: "Liver Function Test", key: 'liver_function_test'},
  {label: "Kidney Function Test", key: 'kidney_function_test'},
  {label: "Hba1c", key: 'hba1c'},
  {label: "CBC", key: 'cbc'},
  {label: "Vitamin B12", key: 'vitamin_b12'},
  {label: "Vitamin D", key: 'vitamin_d'},
  {label: "GGT", key: 'ggt'},
  {label: "Phosphorus", key: 'phosphorus'},
  {label: "Hepatitis B", key: 'hepatitis_b'},
  {label: "Iron Test", key: 'iron_test'},
  {label: "Urine Test", key: 'urine_test'},
  {label: "ESR", key: 'esr'},
];

export const LabTestsJSON = {
  "data": {
    "lipid_profile": { value: ""},
    "thyroid_profile": { value: ""},
    "liver_function_test": { value: ""},
    "kidney_function_test": { value: ""},
    "hba1c": { value: ""},
    "cbc": { value: ""},
    "vitamin_b12": { value: ""},
    "vitamin_d": { value: ""},
    "ggt": { value: ""},
    "phosphorus": { value: ""},
    "hepatitis_b": { value: ""},
    "iron_test": { value: ""},
    "urine_test": { value: ""},
    "esr": { value: ""},
  },
  "conducted_by": "",
  "date": ""
};

export const AyurvedaArrivalConsultationField = {
  "currentDietaryPattern": {
    "intake": [
      { label: "Breakfast", key: "breakfast" },
      { label: "Afternoon Snack", key: "afternoon_snack" },
      { label: "Lunch", key: "lunch" },
      { label: "Evening Snack", key: "evening_snack" },
      { label: "Dinner", key: "dinner" },
      { label: "Late Night Snack", key: "night_snack" }
    ],
    "additionalInfo": [
      { label: "Plain Water", key: "pain_water" },
      { label: "Dairy Products", key: "dairy_products" },
      { label: "Coffee/Tea", key: "coffee_tea" },
      { label: "Cold Drink/Soda", key: "cold_drink_soda" },
      { label: "Herbal Tea/Juice", key: "herbal_tea_juice" },
      { label: "Alcohol", key: "alcohal" }
    ]
  },
  "healthAssessment": [
    { label: "AGNI (DIGESTION)", key: "agni" },
    { label: "KOSHTHA (GUT BEHAVIOUR)", key: "koshtha" },
    { label: "MALA (EXCRETORY FUNCTION)", key: "mala" },
    { label: "AMA (TOXIN LEVEL)", key: "ama" },
    { label: "MANAS (MENTAL HEALTH)", key: "manas" },
    { label: "NIDRA (SLEEP)", key: "nidra" },
    { label: "VYAYAMA (EXERCISE)", key: "vyayama" },
  ],
  "ashthavidhaPariksha": [
    { label: "NADI (examination of pulse)", key: "nadi" },
    { label: "JIHWA (examination of tongue)", key: "jihwa" },
    { label: "MALA (examination of stool)", key: "mala" },
    { label: "MUTRA (examination of urine)", key: "mutra" },
    { label: "SHABDHA (auscultation)", key: "shabdha" },
    { label: "SPARSHA (palpation)", key: "sparsha" },
    { label: "DRIK (inspection)", key: "drik" },
    { label: "AKRITI (examination of whole body)", key: "akriti" }
  ],
  "objectives": [
    { label: "Objective", key: "objective" },
    { label: "Treatment protocol", key: "treatment_protocol" },
    { label: "Follow during stay", key: "follow_during_stay" },
    { label: "Avoid during stay", key: "avoid_during_stay" },
    { label: "Medicines/ Tests prescribed", key: "medicines" },
  ]
};

export const AyurvedaArrivalConsultationJSON = {
  "data": {
    "current_health_complaints": {
      "is_current_health_complaint_physical": "",
      "health_complaint_keywords_physical" : [{
        "condition": "",
        "intensity": "",
        "duration": ""
      }],
      "is_current_health_complaint_mental": "",
      "health_complaint_keywords_mental" : [{
        "condition": "",
        "intensity": "",
        "duration": ""
      }],
    },
    "current_dietary_pattern": {
      "intake" : {
        "breakfast": "",
        "afternoon_snack": "",
        "lunch": "",
        "evening_snack": "",
        "dinner": "",
        "night_snack": ""
      },
      "additional_info": {
        "plain_water": "",
        "dairy_products": "",
        "coffee_tea": "",
        "cold_drink_soda": "",
        "herbal_tea_juice": "",
        "alcohal": ""
      }
    },
    "ayurvedic_health_assessment": {
      "agni": "",
      "koshtha": "",
      "mala": "",
      "ama": "",
      "manas": "",
      "nidra": "",
      "vyayama": "",
    },
    "systemic_assessment": {
      "respiratory_system": {
        "lung_field": "",
        "auscultation": ""
      },
      "cardiovascular_system": {
        "pulse_rate": {value: "", unit: "bpm"},
        "blood_pressure": {value: "", unit: "mm Hg"},
        "spo2": {value: "", unit: "%"},
        "remarks": ""
      },
      "musculoskeletal_system": "",
      "skin": "",
    },
    "ashthavidha_pariksha": {
      "nadi": "",
      "jihwa": "",
      "mala": "",
      "mutra": "",
      "shabdha": "",
      "sparsha": "",
      "drik": "",
      "akriti": ""
    },
    "lab_reports": "",
    "summary_assessment_treatment_plan": {
      "dosha_prakriti": "",
      "dosha_vikruiti": "",
      "objectives": [
        {
          "treatment_protocol": "",
          "follow_during_stay": "",
          "avoid_during_stay":"",
          "medicines": ""
        }
      ],
    }
  },
  "conducted_by": "",
  "date": ""
}


export const YogaArrivalConsultationField = {
    "areaOfDiscomfort": [
      { label: "Shoulders", key: "shoulders" },
      { label: "Neck", key: "neck" },
      { label: "Spine", key: "spine" },
      { label: "Hips", key: "hips" },
      { label: "Legs", key: "legs" }
    ],
    "yogicAssessment": [
      { label: "Physical Balance", key: "physical_balance" },
      { label: "Mental Adaptability", key: "mental_adaptability" },
      { label: "Physical Flexibility", key: "physical_flexibility" },
      { label: "Mental Receptivity", key: "mental_receptivity" }
    ],
    "breathingPatterns": [
      { label: "Shallow Chest Breathing", key: "shallow_chest_breathing" },
      { label: "Mouth Breathing", key: "mouth_breathing" },
      { label: "Irregular Breathing", key: "irregular_breathing" },
      { label: "Holding the Breath Unnecessarily", key: "holding_the_breath_unnecessarily" },
      { label: "Abdominal Breathing", key: "abdominal_breathing" }
    ],
    "others": [
      { label: "Energy levels or vitality", key: "energy_levels" },
      { label: "Health of joints", key: "health_of_joints" },
      { label: "State of digestive health", key: "digestive_health" },
      { label: "Disturbances in sleep pattern", key: "sleep_disturbances" },
    ],
    "mentalEmotionalWellbeing": [
      { label: "Approach a Therapist for Support", key: "approach_a_therapist_for_support" },
      { label: "Withdraw to Reflect", key: "withdraw_to_reflect" },
      { label: "Indulge in Drinking", key: "indulge_in_drinking" },
      { label: "Eat to Improve Mood", key: "eat_to_improve_mood" },
      { label: "Engage in Enjoyable Activities to Shift Focus", key: "engage_in_enjoyable_activities_to_shift_focus" }
    ],
    "objectives": [
      { label: "Objective", key: "objective" },
    { label: "Treatment protocol", key: "treatment_protocol" },
      { label: "Follow during stay", key: "follow_during_stay" },
      { label: "Avoid during stay", key: "avoid_during_stay" },
      // { label: "Medicines/ Tests prescribed", key: "medicines" },
    ]
  }
  
  export const YogaArrivalConsultationJSON = {
    "data": {
      "experience_in_yoga_pranayama": "",
      "experience_in_meditation": "",
      "forms_of_activity": "",
      "area_of_discomfort": {
        "shoulders": { value: "No", frequency: "", reasons: "" },
        "neck": { value: "No", frequency: "", reasons: "" },
        "spine": { value: "No", frequency: "", reasons: "" },
        "hips": { value: "No", frequency: "", reasons: "" },
        "legs": { value: "No", frequency: "", reasons: "" }
      },
      "yogic_assessment":  {
        "physical_balance": "",
        "mental_adaptability": "",
        "physical_flexibility": "",
        "mental_receptivity": ""
      },
      "breathing_pattern": {
        "shallow_chest_breathing": "",
        "mouth_breathing": "",
        "irregular_breathing": "",
        "holding_the_breath_unnecessarily": "",
        "abdominal_breathing": "",
        "inhale_exhale_ratio": "",
      },
      "others": {
        "energy_levels": "",
        "health_of_joints": "",
        "digestive_health": "",
        "sleep_disturbances": "",
      },
      "mental_emotional_wellbeing": {
        "approach_a_therapist_for_support": "No",
        "withdraw_to_reflect": "No",
        "indulge_in_drinking": "No",
        "eat_to_improve_mood": "No",
        "engage_in_enjoyable_activities_to_shift_focus": "No",
        "other_points": "",
      },
      "summary_assessment_treatment_plan": {
        "objectives": [
          {
            "objective": "",
            "treatment_protocol": "",
            "follow_during_stay": "",
            "avoid_during_stay":"",
            "medicines": ""
          }
        ],
      }
    },
    "conducted_by": "",
    "date": ""
  };
  


export const TCMArrivalConsultationField = {
  "doctorExamination": [
    { label: "Constitutional Imbalance", key: "constitutional_imbalance" },
    { label: "Tongue Diagnosis", key: "tongue_diagnosis" },
    { label: "Pulse Diagnosis", key: "pulse_diagnosis" },
    { label: "5 Element Imbalance", key: "five_element_imbalance" },
    { label: "Emotional Imbalance", key: "emotional_imbalance" }
  ],
  "objectives": [
    { label: "Objective", key: "objective" },
    { label: "Treatment protocol", key: "treatment_protocol" },
    { label: "Follow during stay", key: "follow_during_stay" },
    { label: "Avoid during stay", key: "avoid_during_stay" },
    // { label: "Medicines/ Tests prescribed", key: "medicines" },
  ]
};

export const TCMArrivalConsultationJSON = {
  "data": {
    "systemic_assessment": {
      "respiratory_system": {
        "lung_field": "",
        "auscultation": ""
      },
      "cardiovascular_system": {
        "pulse_rate": {value: "", unit: "bpm"},
        "blood_pressure": {value: "", unit: "mm Hg"},
        "spo2": {value: "", unit: "%"},
        "remarks": ""
      },
      "musculoskeletal_system": "",
      "skin": "",
      "doctor_examination": {
        "constitutional_imbalance": "",
        "tongue_diagnosis": "",
        "pulse_diagnosis": "",
        "five_element_imbalance": "",
        "emotional_imbalance": ""
      },
    },
    "summary_assessment_treatment_plan": {
      "objectives": [
        {
          "objective": "",
          "treatment_protocol": "",
          "follow_during_stay": "",
          "avoid_during_stay":"",
          "medicines": ""
        }
      ],
    }
  },
  "conducted_by": "",
  "date": ""
}


export const PhysioArrivalConsultationField = {
  "numericPainRating": [
    { label: "Neck", key: "neck" },
    { label: "Shoulder", key: "shoulder" },
    { label: "Arms", key: "arms" },
    { label: "Upper Back", key: "upper_back" },
    { label: "Elbows", key: "elbows" },
    { label: "Forearms", key: "forearms" },
    { label: "Wrist", key: "wrist" },
    { label: "Hands", key: "hands" },
    { label: "Lower Back", key: "lower_back" },
    { label: "Hips", key: "hips" },
    { label: "Thighs", key: "thighs" },
    { label: "Knees", key: "knees" },
    { label: "Calf", key: "calf" },
    { label: "Ankles", key: "ankles" },
    { label: "Feet", key: "feet" }
],
"painSymptoms": [
  {
    label: "Onset Of Pain",
    key: "onset_of_pain",
    options: [
      "Sudden",
      "Insidious",
      "Gradual",
      "Precipitation of pain by trauma"
    ]
  },
  {
    label: "Presentation of Pain",
    key: "presentation_of_pain",
    options: [
      "Dull",
      "Ache",
      "Sharp",
      "Burning"
    ]
  }
],
"aboutPain": [
  {
      label: "Effect of Activity on Pain",
      key: "effect_of_activity_on_pain",
      options: [
          "Pain comes at rest",
          "Pain comes with activity",
          "Pain comes when activity is prolonged"
      ]
  },
  {
      label: "Nature of the Pain",
      key: "nature_of_pain",
      options: [
          "Continuous",
          "Intermittent",
          "Comes and goes with activity only",
          "Only with certain positions"
      ]
  },
  {
      label: "Pain Pattern",
      key: "pain_pattern",
      options: [
          "Comes on same side of movement",
          "Comes on opposite side of movement",
          "Comes on both sides of movement"
      ]
  },
  {
      label: "Time of Pain Aggravation",
      key: "time_of_pain_aggravation",
      options: [
          "Early morning",
          "During day",
          "At the end of the day",
          "While sleeping"
      ]
  },
  {
      label: "If More in Early Morning",
      key: "if_more_in_early_morning",
      options: [
          "Does pain go away after slightest movement",
          "Pain persists for few minutes",
          "Pain continuous for 1 hour and gradually wears off",
          "Pain continuous for a long time in a day"
      ]
  },
  {
      label: "Perceived Ease of Movement",
      key: "perceived_ease_of_movement",
      options: [
          "Feel normal",
          "Mild stiffness",
          "Moderate to severe stiffness",
          "Feel unstable"
      ]
  }
],
"painAndFunctionalAssessment": [
  {
      label: "Tenderness",
      key: "tenderness"
  },
  {
      label: "Spasm",
      key: "spasm"
  },
  {
      label: "End Feel",
      key: "end_feel"
  },
  {
      label: "Range of Motion",
      key: "range_of_motion"
  },
  {
      label: "Capsular Patterns",
      key: "capsular_patterns"
  },
  {
      label: "Manual Muscle Testing",
      key: "manual_muscle_testing"
  }
],
"functionalAssessment": [
  {
      label: "Single Leg Squat, Single Leg Hop, Star Excursion Balance Test (SEBT)",
      key: "star_excursion_balance_test"
  },
  {
      label: "Closed Kinetic Chain Upper Extremity Stability Test (CKCUEST)",
      key: "ckcuest"
  },
  {
      label: "Special Tests",
      key: "special_tests"
  },
  {
      label: "Gait Assessment (if applicable)",
      key: "gait_assessment",
      haveMultipleValue: true 
  },
  {
      label: "Posture (if applicable)",
      key: "posture",
      haveMultipleValue: true 
  }
],
 "objectives": [
    { label: "Objective", key: "objective" },
    { label: "Treatment protocol", key: "treatment_protocol" },
    { label: "Follow during stay", key: "follow_during_stay" },
    { label: "Avoid during stay", key: "avoid_during_stay" },
    // { label: "Medicines/ Tests prescribed", key: "medicines" },
  ]
};

export const PhysioArrivalConsultationJSON = {
  "data": {
      "numeric_pain_rating": {
          "neck": {
            "value": 0,
            "remarks": ""
          },
          "shoulder": {
            "value": 0,
            "remarks": ""
          },
          "arms": {
            "value": 0,
            "remarks": ""
          },
          "upper_back": {
            "value": 0,
            "remarks": ""
          },
          "elbows": {
            "value": 0,
            "remarks": ""
          },
          "forearms": {
            "value": 0,
            "remarks": ""
          },
          "wrist": {
            "value": 0,
            "remarks": ""
          },
          "hands": {
            "value": 0,
            "remarks": ""
          },
          "lower_back": {
            "value": 0,
            "remarks": ""
          },
          "hips": {
            "value": 0,
            "remarks": ""
          },
          "thighs": {
            "value": 0,
            "remarks": ""
          },
          "knees": {
            "value": 0,
            "remarks": ""
          },
          "calf": {
            "value": 0,
            "remarks": ""
          },
          "ankles": {
            "value": 0,
            "remarks": ""
          },
          "feet": {
            "value": 0,
            "remarks": ""
          }
      },
      "pain_symptom": {
          "onset_of_pain": "",
          "presentation_of_pain": ""
      },
      "activities_that_relives_symptoms": "",
      "activities_that_increases_symptoms": "",
      "effect_of_activity_on_pain": "",
      "nature_of_pain": "",
      "pain_pattern": "",
      "time_of_pain_aggravation": "",
      "if_more_in_early_morning": "",
      "perceived_ease_of_movement": "",
      "pain_and_functional_assessment": {
          "tenderness": "",
          "spasm": "",
          "end_feel": "",
          "range_of_motion": "",
          "capsular_patterns": "",
          "manual_muscle_testing": ""
      },
      "functional_assessment": {
          "star_excursion_balance_test": "",
          "ckcuest": "",
          "special_tests": "",
          "gait_assessment": [""],
          "posture": [""]
      },
      "summary_assessment_treatment_plan": {
          "objectives": [
            {
              "objective": "",
              "treatment_protocol": "",
              "follow_during_stay": "",
              "avoid_during_stay": "",
              "medicines": ""
            }
          ]
      }
  },
  "conducted_by": "",
  "date": ""
}

export const AyurvedaDepartureConsultationField = {
  "objectives": [
    { label: "Objective", key: "objective" },
    { label: "Treatment protocol", key: "treatment_protocol" },
    // { label: "Follow during stay", key: "follow_during_stay" },
    // { label: "Avoid during stay", key: "avoid_during_stay" },
    { label: "Medicines/ Tests prescribed", key: "medicines" },
  ],
  "healthAssessment": [
    { label: "Agni", key: "agni" },
    { label: "Koshtha", key: "koshtha" },
    { label: "Mala", key: "mala" },
    { label: "Ama", key: "ama" },
    { label: "Manas", key: "manas" },
    { label: "Nidra", key: "nidra" },
    { label: "Vyayama", key: "vyayama" },
  ],
  "ashthavidhaPariksha": [
    { label: "NADI (examination of pulse)", key: "nadi" },
    { label: "JIHWA (examination of tongue)", key: "jihwa" },
    { label: "MALA (examination of stool)", key: "mala" },
    { label: "MUTRA (examination of urine)", key: "mutra" },
    { label: "SHABDHA (auscultation)", key: "shabdha" },
    { label: "SPARSHA (palpation)", key: "sparsha" },
    { label: "DRIK (inspection)", key: "drik" },
    { label: "AKRITI (examination of whole body)", key: "akriti" }
  ],
  "recommendationsAndFollowUps": [
    { label: "Wake Up", key: "wake_up" },
    { label: "Morning Infusion", key: "morning_infusion" },
    { label: "Bowel Evacuation", key: "bowel_evacuation" },
    { label: "Tongue Scraping", key: "tongue_scraping" },
    { label: "Oil Pulling", key: "oil_pulling" },
    { label: "Eye Wash", key: "eye_wash" },
    { label: "Nasal Drops", key: "nasal_drops" },
    { label: "Yoga", key: "yoga" },
    { label: "Abhyanga", key: "abhyanga" },
    { label: "Breakfast Timing", key: "breakfast_timing" },
    { label: "Morning Snack", key: "morning_snack" },
    { label: "Lunch Timing", key: "lunch_timing" },
    { label: "Late Afternoon Snack", key: "late_afternoon_snack" },
    { label: "Meditation", key: "meditation" },
    { label: "Dinner Timing", key: "dinner_timing" },
    { label: "Foot Massage", key: "foot_massage" },
    { label: "Sleep Time", key: "sleep_time" }
  ],
  "otherRecommendations": {
    "dietaryRecommendation": [
      { label: "Favourable Foods", key: "favourable_foods" },
      { label: "Foods to Consume in Moderation", key: "foods_to_consume_in_moderation" }, 
      { label: "Foods to Strictly Avoid", key: "foods_to_strictly_avoid" },
      { label: "Supplements Recommended", key: "supplements_recommended" }
    ],
    "physicalAndMentalActivity": [
        { label: "Incorporate Daily", key: "incorporate_daily" },
        { label: "Reduce", key: "reduce" },
        { label: "Avoid", key: "avoid" },
      ]
    },
  
}

export const AyurvedaDepartureConsultationJSON = {
  "data": {
    "programme_and_treatment_plan" : {
      "dosha_prakriti": "",
      "dosha_vikruiti": "",
      "objectives": []
    },
    "health_assessment": {
      "agni": {"value": ""},
      "koshtha": {"value": ""},
      "mala": {"value": ""},
      "ama": {"value": ""},
      "manas": {"value": ""},
      "nidra": {"value": ""},
      "vyayama": {"value": ""},
    },
    "systemic_assessment": {
      "respiratory_system": {
        "lung_field": {"arrival_reading": "", "value": ""},
        "auscultation": {"arrival_reading": "", "value": ""}
      },
      "cardiovascular_system": {
        "pulse_rate": {"arrival_reading": "", "value": "", unit: "bpm"},
        "blood_pressure": {"arrival_reading": "", "value": "", unit: "mm Hg"},
        "spo2": {"arrival_reading": "", "value": "", unit: "%"},
        "remarks": {"arrival_reading": "", "value": ""},
      },
      "musculoskeletal_system": {"arrival_reading": "", "value": ""},
      "skin": {"arrival_reading": "", "value": ""},
    },
    "ashthavidha_pariksha": {
      "nadi": {"arrival_reading": "", "value": ""},
      "jihwa": {"arrival_reading": "", "value": ""},
      "mala": {"arrival_reading": "", "value": ""},
      "mutra": {"arrival_reading": "", "value": ""},
      "shabdha": {"arrival_reading": "", "value": ""},
      "sparsha": {"arrival_reading": "", "value": ""},
      "drik": {"arrival_reading": "", "value": ""},
      "akriti": {"arrival_reading": "", "value": ""}
    },
    "lab_reports": {"arrival_reading": "", "value": ""},
    
    "recommendations_and_follow_ups": {
      "wake_up": { "time": "", "frequency": "" },
      "morning_infusion": { "time": "", "frequency": "" },
      "bowel_evacuation": { "time": "", "frequency": "" },
      "tongue_scraping": { "time": "", "frequency": "" },
      "oil_pulling": { "time": "", "frequency": "" },
      "eye_wash": { "time": "", "frequency": "" },
      "nasal_drops": { "time": "", "frequency": "" },
      "yoga": { "time": "", "frequency": "" },
      "abhyanga": { "time": "", "frequency": "" },
      "breakfast_timing": { "time": "", "frequency": "" },
      "morning_snack": { "time": "", "frequency": "" },
      "lunch_timing": { "time": "", "frequency": "" },
      "late_afternoon_snack": { "time": "", "frequency": "" },
      "meditation": { "time": "", "frequency": "" },
      "dinner_timing": { "time": "", "frequency": "" },
      "foot_massage": { "time": "", "frequency": "" },
      "sleep_time": { "time": "", "frequency": "" }
    },
    "other_recommendations": {
      "dietary": {
        "favourable_foods": "",
        "foods_to_consume_in_moderation": "",
        "foods_to_strictly_avoid": "",
        "supplements_recommended": ""
      },
      "physical_mental": {
        "incorporate_daily": "",
        "reduce": "",
        "avoid": ""
      },
      "medicine": [{
        "medicines": "",
        "dosage": "",
        "frequency": ""
      }]
    },
    "follow_up_programme":{
      "consultation": "Within 30 days of departure",
      "programme": "",
      "timeline": "",
    }
  },
  "conducted_by": "",
  "date": ""
}


export const YogaDepartureConsultationField = {
  "objectives": [
    { label: "Objective", key: "objective" },
    { label: "Treatment protocol", key: "treatment_protocol" },
    // { label: "Follow during stay", key: "follow_during_stay" },
    // { label: "Avoid during stay", key: "avoid_during_stay" },
    // { label: "Medicines/ Tests prescribed", key: "medicines" },
  ],
  "healthAssessment": [
    { label: "Physical Flexibility", key: "physical_flexibility", radio: true },
    { label: "Physical Balance", key: "physical_balance", radio: true },
    { label: "Mental Receptivity", key: "mental_receptivity", radio: true },
    { label: "Mental Adaptability", key: "mental_adaptability", radio: true },
    { label: "Energy Levels or Vitality", key: "energy_levels" },
    { label: "Health of Joints", key: "health_of_joints" },
    { label: "State of Digestive Health", key: "digestive_health" },
    { label: "Disturbances in Sleep Pattern", key: "sleep_disturbances" },
    { label: "Breath (Inhale:Exhale Ratio)*", key: "breath_inhale_exhale_ratio" }
  ],
  "recommendationsAndFollowUps": [
    { label: "PM1", key: "pm1" },
    { label: "PM2", key: "pm2" },
    { label: "PM3", key: "pm3" },
    { label: "Standing Asanas", key: "standing_asanas" },
    { label: "Balancing", key: "balancing" },
    { label: "Twisting Asanas", key: "twisting_asanas" },
    { label: "Inversions", key: "inversions" },
    { label: "Forward Bends", key: "forward_bends" },
    { label: "Backbends", key: "backbends" },
    { label: "Seated Asanas", key: "seated_asanas" },
    { label: "Meditation", key: "meditation" },
    { label: "Yoga Nidra", key: "yoga_nidra" },
    { label: "Pranayama", key: "pranayama" },
    { label: "Mindful Practices", key: "mindful_practices" }
  ]
}

export const YogaDepartureConsultationJSON = {
  "data": {
    "programme_and_treatment_plan" : {
      "objectives": []
    },
    "health_assessment": {
      "physical_flexibility": { "value": "" },
      "physical_balance": { "value": "" },
      "mental_receptivity": { "value": "" },
      "mental_adaptability": { "value": "" },
      "energy_levels": { "value": "" },
      "health_of_joints": { "value": "" },
      "digestive_health": { "value": "" },
      "sleep_disturbances": { "value": "" },
      "breath_inhale_exhale_ratio": { "value": "" }
    },
    "recommendations_and_follow_ups": {
      "pm1": {"details": "", "time": "", "duration": "", "frequency": ""},
      "pm2": {"details": "", "time": "", "duration": "", "frequency": ""},
      "pm3": {"details": "", "time": "", "duration": "", "frequency": ""},
      "standing_asanas": {"details": "", "time": "", "duration": "", "frequency": ""},
      "balancing": {"details": "", "time": "", "duration": "", "frequency": ""},
      "twisting_asanas": {"details": "", "time": "", "duration": "", "frequency": ""},
      "inversions": {"details": "", "time": "", "duration": "", "frequency": ""},
      "forward_bends": {"details": "", "time": "", "duration": "", "frequency": ""},
      "backbends": {"details": "", "time": "", "duration": "", "frequency": ""},
      "seated_asanas": {"details": "", "time": "", "duration": "", "frequency": ""},
      "meditation": {"details": "", "time": "", "duration": "", "frequency": ""},
      "yoga_nidra": {"details": "", "time": "", "duration": "", "frequency": ""},
      "pranayama": {"details": "", "time": "", "duration": "", "frequency": ""},
      "mindful_practices": {"details": "", "time": "", "duration": "", "frequency": ""},
    },
    "follow_up_programme":{
      "consultation": "Within 30 days of departure",
      "programme": "",
      "timeline": "",
    }
  },
  "conducted_by": "",
  "date": ""
}


export const TCMDepartureConsultationField = {
  "objectives": [
    { label: "Objective", key: "objective" },
    { label: "Treatment protocol", key: "treatment_protocol" },
    // { label: "Follow during stay", key: "follow_during_stay" },
    // { label: "Avoid during stay", key: "avoid_during_stay" },
    // { label: "Medicines/ Tests prescribed", key: "medicines" },
  ],
  "healthAssessment": [
    { label: "Constitutional Imbalance", key: "constitutional_imbalance" },
    { label: "Tongue Diagnosis", key: "tongue_diagnosis" },
    { label: "Pulse Diagnosis", key: "pulse_diagnosis" },
    { label: "5 Element Imbalance", key: "five_element_imbalance" },
    { label: "Emotional Imbalance", key: "emotional_imbalance" }
  ],
}

export const TCMDepartureConsultationJSON = {
  "data": {
    "programme_and_treatment_plan" : {
      "objectives": []
    },
    "health_assessment": {
      "constitutional_imbalance": {"value": ""},
      "tongue_diagnosis": {"value": ""},
      "pulse_diagnosis": {"value": ""},
      "five_element_imbalance": {"value": ""},
      "emotional_imbalance": {"value": ""},
    },
    "recommendations_and_follow_ups": [{
      "recommendation": "",
      "details": "",
      "time": "",
      "duration": "",
      "frequency": ""
    }],
    "follow_up_programme":{
      "consultation": "Within 30 days of departure",
      "programme": "",
      "timeline": "",
    }
  },
  "conducted_by": "",
  "date": ""
}

export const PhysioDepartureConsultationField = {
  "objectives": [
    { label: "Objective", key: "objective" },
    { label: "Treatment protocol", key: "treatment_protocol" },
    // { label: "Follow during stay", key: "follow_during_stay" },
    // { label: "Avoid during stay", key: "avoid_during_stay" },
    // { label: "Medicines/ Tests prescribed", key: "medicines" },
  ],
  "healthAssessment": [
    { label: "Presentation of Pain (Intensity)", key: "presentation_of_pain", options: [
      "Dull",
      "Ache",
      "Sharp",
      "Burning"
    ]},
    { label: "Effect of Activity on Pain", key: "effect_of_activity_on_pain", options: [
      "Pain comes at rest",
      "Pain comes with activity",
      "Pain comes when activity is prolonged"
  ]},
    { label: "Nature of Pain (Recurrence)", key: "nature_of_pain", options: [
      "Continuous",
      "Intermittent",
      "Comes and goes with activity only",
      "Only with certain positions"
  ]},
    { label: "Perceived Ease of Movement", key: "perceived_ease_of_movement", options: [
      "Feel normal",
      "Mild stiffness",
      "Moderate to severe stiffness",
      "Feel unstable"
  ]}
  ],
  "functionalAssessment": [
    { label: "Tenderness", key: "tenderness" },
    { label: "Spasm", key: "spasm" },
    // { label: "End Feel", key: "end_feel" },
    { label: "Range of Motion (ROM)", key: "range_of_motion" },
    { label: "Capsular Patterns", key: "capsular_patterns" },
    { label: "Manual Muscle Testing", key: "manual_muscle_testing" },
    // { label: "Gait (if applicable)", key: "gait_assessment", haveMultipleValue: true },
    // { label: "Posture (if applicable)", key: "posture", haveMultipleValue: true }
  ],
  "recommendationsAndFollowUps": [
    { label: "Posture Correction", key: "posture_correction", isArray: true},
    { label: "Exercise", key: "exercises", isArray: true},
    // { label: "Protein Intake", key: "protien_intake" },
    // { label: "Dietary Supplements", key: "dietary_supplements" }
  ]
}

export const PhysioDepartureConsultationJSON = {
  "data": {
    "programme_and_treatment_plan" : {
      "objectives": []
    },
    "health_assessment": {
      "presentation_of_pain": { "value": "" },
      "effect_of_activity_on_pain": { "value": "" },
      "nature_of_pain": { "value": "" },
      "perceived_ease_of_movement": { "value": "" }
    },
    "functional_assessment": {
      "tenderness": { "value": "" },
      "spasm": { "value": "" },
      "end_feel": { "value": "" },
      "range_of_motion": { "value": "" },
      "capsular_patterns": { "value": "" },
      "manual_muscle_testing": { "value": "" },
      "gait_assessment": [{ "value": "" }],
      "posture": [{ "value": "" }]  
    },
    "recommendations_and_follow_ups": {
      "posture_correction": [{
        "daily_routine": "",
        "details": "",
        "time": "",
        "duration": "",
        "frequency": ""
      }],
      "exercises": [{
        "daily_routine": "",
        "details": "",
        "time": "",
        "duration": "",
        "frequency": ""
      }],
      // "protien_intake": [{
      //   "details": "",
      //   "time": "",
      //   "duration": "",
      //   "frequency": ""
      // }],
      // "dietary_supplements": [{
      //   "details": "",
      //   "time": "",
      //   "duration": "",
      //   "frequency": ""
      // }]
    },
    "follow_up_programme":{
      "consultation": "Within 30 days of departure",
      "programme": "",
      "timeline": "",
    }
  },
  "conducted_by": "",
  "date": ""
}

export const PostStayConsultationField = {
  "healthAssessment": [
      { label: "Agni", key: "agni" },
      { label: "Koshtha", key: "koshtha" },
      { label: "Mala", key: "mala" },
      { label: "Ama", key: "ama" },
      { label: "Manas", key: "manas" },
      { label: "Nidra", key: "nidra" },
      { label: "Vyayama", key: "vyayama" },
    ],
}

export const PostStayConsultationJSON = {
  "data": {
    "objectives": [],
    // "health_assessment": [{
    //   "parameter": "",
    //   "on_departure": "",
    //   "psc": ""
    // }],
    "health_assessment": {
      "agni": {"value": "", "on_departure": ""},
      "koshtha": {"value": "", "on_departure": ""},
      "mala": {"value": "", "on_departure": ""},
      "ama": {"value": "", "on_departure": ""},
      "manas": {"value": "", "on_departure": ""},
      "nidra": {"value": "", "on_departure": ""},
      "vyayama": {"value": "", "on_departure": ""},
    },
    "concerns_challenges": [{
      "parameter": "",
      "details": "",
      "changes": ""
    }],
    "recommendation_and_follow_ups": [{
      "recommendations": "",
      "details": "",
      "time": "",
      "duration": "",
      "frequency": ""
    }],
    "follow_up_programme":{
      "consultation": "Within 90 days of departure",
      "programme": "",
      "timeline": "",
    }
  },
  "conducted_by": "",
  "date": ""
}


export const dynamicNotesType = [
  { title: 'Dietary Notes', component: AddEditDietaryNotes, json: DietrayNoteJSON, hideVisibilityIcon: true},
  { title: 'Vitals Check', component: AddEditVitalsCheck, json: VitalsCheckJSON, hideVisibilityIcon: true},
  // { title: 'Vitals Check - Departure', component: AddEditVitalsCheck, json: VitalsCheckJSON, type: DEPARTURE , hideVisibilityIcon: true},
  { title: 'Lab Tests', component: AddEditLabTests, json: LabTestsJSON , hideVisibilityIcon: true},
  // { title: 'Lab Tests - Departure', component: AddEditLabTests, json: LabTestsJSON , type: DEPARTURE , hideVisibilityIcon: true},
  { title: 'Ayurveda Arrival Consultation', component: AddEditAyurvedaArrivalConsultation, json: AyurvedaArrivalConsultationJSON , hideVisibilityIcon: true},
  { title: 'Ayurveda Departure Consultation', component: AddEditAyurvedaDepartureConsultation, json: AyurvedaDepartureConsultationJSON, type: DEPARTURE, hideVisibilityIcon: true},
  { title: 'Yoga Arrival Consultation', component: AddEditYogaArrivalConsultation, json: YogaArrivalConsultationJSON, hideVisibilityIcon: true},
  { title: 'Yoga Departure Consultation', component: AddEditYogaDepartureConsultation, json: YogaDepartureConsultationJSON , hideVisibilityIcon: true},
  { title: 'Physio Arrival Consultation', component: AddEditPhysioArrivalConsultation, json: PhysioArrivalConsultationJSON , hideVisibilityIcon: true},
  { title: 'Physio Departure Consultation', component: AddEditPhysioDepartureConsultation, json: PhysioDepartureConsultationJSON, hideVisibilityIcon: true},
  { title: 'TCM Arrival Consultation', component: AddEditTCMArrivalConsultation, json: TCMArrivalConsultationJSON , hideVisibilityIcon: true},
  { title: 'TCM Departure Consultation', component: AddEditTCMDepartureConsultation, json: TCMDepartureConsultationJSON , hideVisibilityIcon: true},
  { title: 'Post Stay Consultation', component: AddEditPostStayConsultation, json: PostStayConsultationJSON , hideVisibilityIcon: true},
]


export const fileTypes = [
  'application/pdf',         // .pdf
  'image/jpeg',              // .jpg, .jpeg
  'image/png',                // .png
  'image/svg+xml' 
]

// POST STAY - CONSULTATION