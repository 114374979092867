import moment from 'moment'
import {TCMArrivalConsultationField } from '../NoteFormsConstants'
import targetIcon from '../../../../assets/images/target.svg'


export const ViewTCMArrivalConsultation = ({content, conductedBy})=>{
    return (
        <div className="my-3 ">
            <div className="rounded border d-flex justify-content-between bg-light p-2 mb-2">
                <div className='d-flex'>
                    <div className="fw-semi-bold mr-2 flex-shrink-0  me-3" >Conducted By:</div>
                    <div className="text-break white-space">{conductedBy || '-'}</div>
                </div>
                <div className='d-flex  w-25'>
                    <div className="fw-semi-bold mr-2 flex-shrink-0  me-3" >Date:</div>
                    <div className="text-break white-space">{content.date? moment(content.date).format('DD MMM, YYYY') : '-'}</div>
                </div>
            </div>

            <div className='border rounded my-3 overflow-hidden'>
                <div className="bg-light text-center py-2 text-brown border-bottom fw-semibold">
                    SYSTEMIC  ASSESSMENT
                </div>
                <div className='m-3'>
                    <div>
                        <div className='fw-semibold  text-secondary mb-3'>RESPIRATORY SYSTEM </div>
                        <div className=''>
                            <div className='d-flex mb-2'>
                                <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>Lungs Field:</div>
                                <div className="text-break white-space">{content.data?.systemic_assessment?.respiratory_system?.lung_field || '-'}</div>
                            </div>
                            <div className='d-flex mb-2'>
                                <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>Auscultation:</div>
                                <div className="text-break white-space">{content.data?.systemic_assessment?.respiratory_system?.auscultation || '-'}</div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <div className='fw-semibold text-secondary my-3'>CARDIOVASCULAR SYSTEM </div>
                        <div className=''>
                            <div className='d-flex mb-2'>
                                <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>Pulse Rate:</div>
                                <div className="text-break white-space">{content.data?.systemic_assessment?.cardiovascular_system?.pulse_rate?.value || '-'} {content.data?.cardiovascular_system?.pulse_rate?.unit}</div>
                            </div>
                            <div className='d-flex mb-2'>
                                <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>Blood pressure:</div>
                                <div className="text-break white-space">{content.data?.systemic_assessment?.cardiovascular_system?.blood_pressure?.value || '-'} {content.data?.cardiovascular_system?.blood_pressure?.unit}</div>
                            </div>
                            <div className='d-flex mb-2'>
                                <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>SpO<sub>2</sub>:</div>
                                <div className="text-break white-space">{content.data?.systemic_assessment?.cardiovascular_system?.spo2?.value || '-'} {content.data?.cardiovascular_system?.spo2?.unit}</div>
                            </div>
                            <div className='d-flex my-2'>
                                <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>Remarks:</div>
                                <div className="text-break white-space">{content.data?.systemic_assessment?.cardiovascular_system?.remarks || '-'}</div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <div className='fw-semibold text-secondary my-1'>MUSCULOSKELETAL SYSTEM </div>
                        <div className="text-break white-space">{content.data?.systemic_assessment?.musculoskeletal_system || '-'}</div>
                    </div>
                    <hr />
                    <div>
                        <div className='fw-semibold text-secondary my-1'>SKIN </div>
                        <div className="text-break white-space">{content.data?.systemic_assessment?.skin || '-'}</div>
                    </div>
                    <hr/>

                    <div className='py-3'>
                        <div className="fw-semi-bold text-secondary mb-3">
                            TCM DOCTOR'S EXAMINATION
                        </div>
                        {
                            TCMArrivalConsultationField?.doctorExamination?.map((item, index)=>{
                                return (
                                    <div className='d-flex mb-2' key={index}>
                                        <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>{item.label}: </div>
                                        <div className="text-break white-space">{content.data?.systemic_assessment?.doctor_examination?.[item.key] || '-'} {content.data?.cardiovascular_system?.pulse_rate?.unit}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            <div className='border rounded my-3 overflow-hidden'>
                <div className="bg-light text-center py-2 text-brown border-bottom fw-semibold">
                SUMMARY ASSESSMENT AND TREATMENT PLAN
                </div>
                <div className="p-3">
                <div className='fw-semibold mb-3 text-uppercase  text-secondary'>Primary objectives for improvement: <span className="fw-normal text-dark">{content?.data?.summary_assessment_treatment_plan?.objectives?.length ? '' : <>&nbsp; -</>} </span> </div>
                {
                        content?.data?.summary_assessment_treatment_plan?.objectives?.map((item, index)=>{
                            return (
                                <div className='border mb-3 ' key={index}>
                                    <div className='fw-semibold text-secondary p-2 bg-light text-uppercase'> 
                                            <img src={targetIcon} className='me-2' width={'20px'} alt="" />{(index+1)?.toString()?.padStart(2, 0)}

                                        </div>
                                    <table className='p-2 w-100'>
                                        <tbody>
                                        {
                                            TCMArrivalConsultationField?.objectives?.map((item, subIndex)=>(
                                                <tr className='d-flex' key={subIndex}>
                                                    <td className="fw-semi-bold border p-2 mr-2 flex-shrink-0" style={{width: '230px'}}>{item?.label}</td>
                                                    <td className="text-break border flex-grow-1 p-2 white-space">{content.data?.summary_assessment_treatment_plan?.objectives?.[index]?.[item.key] || '-'}</td>
                                                </tr>
                                                ))
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </div>
    )
}
