import { call, put, takeLatest } from "redux-saga/effects";
import axiosInstance from "../../../axios/axiosInstance";
import { notify } from "../../../utils/notification";
import {logOut} from '../../../utils/helpers/logout'
import apiEndpoints from "../../../axios/api";
import { VIEW_PROGRAMME_FEEDBACK_DATA, VIEW_PROGRAMME_FEEDBACK_DATA_FALIURE, VIEW_PROGRAMME_FEEDBACK_DATA_SUCCESS } from "../../constants";

export const getProgrammeFeedbackReq = async({data})=>{   

    return axiosInstance.get(`${apiEndpoints.GET_PROGRAMME_FEEDBACK_API}?user_program_id=${data?.userProgramId }&user_id=${data?.guestId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);

            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            }else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* getProgrammeFeedback({data}){
    try{
        let result = yield call(getProgrammeFeedbackReq, {data})
        if(result.statusCode === 200){
            yield put({ type: VIEW_PROGRAMME_FEEDBACK_DATA_SUCCESS, payload: result.data});  
        }  
    }catch(error){
        yield put({ type: VIEW_PROGRAMME_FEEDBACK_DATA_FALIURE, message: error.message });
        console.log(error)

    }
}


function* ProgrammeFeedbackSaga(){
    yield takeLatest(VIEW_PROGRAMME_FEEDBACK_DATA, getProgrammeFeedback)
}

export default ProgrammeFeedbackSaga;