import React, { useEffect } from 'react'
import TextArea from '../../../../../components/TextArea/TextArea'
import { TextField } from '../../../../../components/TextField/TextField'
import './AddEditPostStayConsultation.scss'
import { ADD, PostStayConsultationField, PostStayConsultationJSON } from '../../NoteFormsConstants'
import DatePicker from '../../../../../components/DatePicker/DatePicker'
import moment from 'moment'
import { getConsultationNotes } from '../../../../../redux/actions/GuestDataActions/ConsultationNotesActions'
import { useDispatch, useSelector } from 'react-redux'
import { getDateObjectFromTimeString, parseJSON } from '../../../../../utils/helpers/universalFunctions'
import { TimeSelector } from '../../../../../components/TimeSelector/TimeSelector'
import { CircularProgress } from '@mui/material'
import { ReactComponent as Add } from '../../../../../assets/images/prelimAdd.svg'
import { ReactComponent as RemoveBtn } from '../../../../../assets/images/delete.svg'
import Select from "react-select";


export const AddEditPostStayConsultation = ({content, setContent, conductedBy, formType,  guestId, userProgramId, guestName, uploadComponent})=>{
    const dispatch = useDispatch()
    const {consultationNotesData, programmeName, followUpTimeline, loading} = useSelector(state=> state.consultationNotesReducer)
    const programList = useSelector(state=> state.programReducer.programList)


  const setKey = (keys, value)=>{
      setContent(prev=>{
        let updatedData = { ...prev}
        keys.reduce((acc, key, index) => {
            if (index === keys.length - 1) {
              acc[key] = value; 
            } else {
                if(Array.isArray(acc[key])){
                    acc[key] = [...acc[key] ]
                }else{
                    acc[key] = { ...acc[key] };
                }
            }
            return acc[key];
          }, updatedData.data);
        return updatedData;
      })
    }

    const handleAddMore = (key) => {
        setContent(prev => {
            let updatedData = { ...prev }
            updatedData.data[key] = [...updatedData.data[key], PostStayConsultationJSON.data[key][0]]
            return updatedData;
        })
    }

    const removeObjectiveWithKey = (key, index) => {
        setContent(prev => {
            const updatedData = {
                ...prev,
                data: {
                    ...prev.data,
                    [key]:  prev.data[key].filter((_, i) => i !== index)
                }
            };
            return updatedData;
        });
    }

    useEffect(()=>{
        if(guestId && userProgramId && formType===ADD){
            dispatch(getConsultationNotes({guestId, userProgramId, noteType: "Ayurveda Departure Consultation"}))
        }
      },[])

      useEffect(()=>{
        let notes_detail = parseJSON(consultationNotesData?.[0]?.list?.[0]?.notes_detail);
        if(formType===ADD){ //have dummy JSON
        setContent(prev=>{
            let newData= {...prev};
            newData.date = moment().format("YYYY-MM-DD")
            newData.data.objectives=[];
            if(notes_detail){
                notes_detail?.data?.programme_and_treatment_plan?.objectives.forEach((item)=>{
                    newData.data.objectives.push(item.objective || "")
                })
                
                for (let key in notes_detail.data.health_assessment) {
                    newData.data.health_assessment[key].on_departure = notes_detail?.data?.health_assessment[key].value
                }
            }
            return newData;
            })
        }
    },[consultationNotesData])



    return (
      <div className='post-stay-consultation-form'>
       {
          loading ?
          <div className="loader mx-auto">
            <CircularProgress sx={{'color': '#74613C'}} size={20}/>
          </div>
          :
          <>
            <>
            <div className="d-flex py-4 mb-2 rounded justify-content-between">
                <div className="d-flex align-items-center">
                    <div className="title text-secondary me-3">Conducted By: </div>
                    {/* <TextField
                        width={'300px'}
                        type={'text'}
                        placeholder='Enter name' 
                        value={content?.conducted_by || ''} 
                        onChange={e=>setContent( {...content, 'conducted_by' : e.target.value})}
                    /> */}
                    {conductedBy || '-'}
                </div>
                <div className="d-flex align-items-center">
                    <div className="title text-secondary me-3">Date</div>
                    <DatePicker
                    value={content.date ? new Date(content.date) : ''}
                    onChange={date=>setContent( {...content, 'date' : moment(date).format('YYYY-MM-DD')})}
                    width={'300px'}
                    />
                </div>
                </div>
                                   
                <div className="border w-100 rounded current-dietary-pattern ">
                <div className="heading py-3">
                    PROGRAMME & TREATMENT PLAN
                </div>
                
                <div className='p-1'>
                    <table className='p-2 w-100'>
                        <tbody>
                        {
                            content?.data?.objectives?.map((item, subIndex)=>{
                                return (
                                    <tr className='d-flex' key={subIndex}>
                                        <td className="fw-semi-bold p-2 mr-2 flex-shrink-0" style={{width: '150px'}}>Objective {(subIndex+1)?.toString()?.padStart(2,0)}: </td>
                                        <td className="text-break flex-grow-1 d-flex   white-space">
                                            <div className="flex-grow-1">
                                                <TextArea
                                                    row={2}
                                                    placeholder={"Enter "}
                                                    onChange={value =>setKey(["objectives", subIndex], value)}
                                                    value={content?.data?.objectives?.[subIndex] || ''}/>
                                            </div>
                                                <div className='remove-btn mx-2 mt-3' onClick={() => {removeObjectiveWithKey("objectives", subIndex)}}>
                                                    <RemoveBtn />
                                                </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                    <div className='cursor-pointer mx-auto mt-3 mb-2' style={{width: 'fit-content'}} onClick={() => {handleAddMore("objectives")}}>
                        <Add />
                    </div>
                </div>
                </div>
                <div className="border w-100 mt-5 rounded healthassessment">
                    <div className="heading py-3">
                    HEALTH ASSESSMENT
                    </div>
                    <div className='p-3'>
                        <div className="row fw-semibold pb-2 border-bottom w-100 mb-3" >
                            <div className="mt-2 col-3">Parameter</div>
                            <div className='col-4'>
                                On Departure
                            </div>
                            <div className='col-5'>
                                Post Stay Consultation
                            </div>
                        </div>
                            {
                                PostStayConsultationField?.healthAssessment?.map((item, index)=>{
                                    return (
                                        <div className="row w-100 mb-3" key={index}>
                                            <div className="title mt-2 col-3">{item.label}</div>
                                            <div className='col-4'>
                                                {content?.data?.health_assessment?.[item.key]?.on_departure || '-'}
                                            </div>
                                            <div className='col-5'>
                                                <div className="data-input h-100">
                                                    <TextArea
                                                        row={3}
                                                        placeholder={"Remarks "}
                                                        fullHeight={true}
                                                        onChange={value =>setKey(["health_assessment", item.key, "value"], value)}
                                                        value={content?.data?.health_assessment?.[item.key]?.value || ''}/>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                    </div>
                </div>

                <div className="border w-100 mt-5 rounded healthassessment">
                    <div className="heading py-3">
                    CONCERNS/ CHALLENGES
                    </div>
                    <div className='p-1'>
                        <div className="row m-0 fw-semibold pb-2 border-bottom w-100 mb-3" >
                            <div className="mt-2 col-3">Parameter</div>
                            <div className='mt-2 col-5'>
                            Details
                            </div>
                            <div className='mt-2 col-4'>
                            Recommendations/ Changes
                            </div>
                        </div>
                        {
                            content?.data?.concerns_challenges?.map((item, index)=>{
                                return(
                                    <div className="row m-0 w-100 " key={index}>
                                        <div className="col-3">
                                            <TextArea
                                                row={2}
                                                placeholder={"Enter "}
                                                onChange={value =>setKey(["concerns_challenges", index, "parameter"], value)}
                                                value={content?.data?.concerns_challenges?.[index]?.parameter || ''}/>
                                        </div>
                                        <div className='col-5'>
                                            <TextArea
                                                row={2}
                                                placeholder={"Enter "}
                                                onChange={value =>setKey(["concerns_challenges", index, "details"], value)}
                                                value={content?.data?.concerns_challenges?.[index]?.details || ''}/>
                                        </div>
                                        <div className='col-4'>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <TextArea
                                                        row={2}
                                                        placeholder={"Enter "}
                                                        onChange={value =>setKey(["concerns_challenges", index, "changes"], value)}
                                                        value={content?.data?.concerns_challenges?.[index]?.changes || ''}/>
                                                </div>
                                                <div className='remove-btn ms-2 mt-3' onClick={() => {removeObjectiveWithKey("concerns_challenges", index)}}>
                                                    <RemoveBtn />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='cursor-pointer mx-auto my-3' style={{width: 'fit-content'}} onClick={() => {handleAddMore("concerns_challenges")}}>
                        <Add />
                    </div>
                </div>
                <div className="border w-100 mt-5 rounded systematic">
                    <div className="heading py-3">
                        RECOMMENDATIONS AND FOLLOW UPS FOR NEXT 2 MONTHS
                    </div>
                    <div className='p-1'>
                        <div className="row m-0 fw-semibold pb-2 border-bottom mb-3" >
                            <div className="mt-2 col-2">Parameter</div>
                            <div className="mt-2 col-3">Details</div>
                            <div className="mt-2 col-2">Time of Practice</div>
                            <div className='mt-2 col-2'>
                            Duration/Repetition 
                            </div>
                            <div className='mt-2 col-3'>
                                Frequency
                            </div>
                        </div>
                        {
                            content?.data?.recommendation_and_follow_ups?.map((item, index)=>{
                                return(
                                    <div className="row m-0 w-100 " key={index}>
                                        <div className="col-2">
                                            <TextArea
                                                row={2}
                                                placeholder={"Enter "}
                                                onChange={value =>setKey(["recommendation_and_follow_ups", index, "recommendations"], value)}
                                                value={content?.data?.recommendation_and_follow_ups?.[index]?.recommendations || ''}/>
                                        </div>
                                        <div className='col-3'>
                                            <TextArea
                                                row={2}
                                                placeholder={"Enter "}
                                                onChange={value =>setKey(["recommendation_and_follow_ups", index, "details"], value)}
                                                value={content?.data?.recommendation_and_follow_ups?.[index]?.details || ''}/>
                                        </div>
                                        <div className='col-2 pt-2'>
                                            <TimeSelector
                                                width={'100%'}
                                                ampm={true}
                                                time={getDateObjectFromTimeString(content?.data?.recommendation_and_follow_ups?.[index]?.time) || ''}
                                                setTime={(value)=>setKey(["recommendation_and_follow_ups", index, "time"], moment(new Date(value)).format('HH:mm A')) }
                                                
                                            />
                                        </div>
                                        <div className='col-2'>
                                            <TextArea
                                                row={2}
                                                placeholder={"Enter "}
                                                onChange={value =>setKey(["recommendation_and_follow_ups", index, "duration"], value)}
                                                value={content?.data?.recommendation_and_follow_ups?.[index]?.duration || ''}/>
                                        </div>
                                        <div className='col-3'>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <TextArea
                                                        row={2}
                                                        placeholder={"Enter "}
                                                        onChange={value =>setKey(["recommendation_and_follow_ups", index, "frequency"], value)}
                                                        value={content?.data?.recommendation_and_follow_ups?.[index]?.frequency || ''}/>
                                                </div>
                                                <div className='remove-btn ms-2 mt-3' onClick={() => {removeObjectiveWithKey("recommendation_and_follow_ups", index)}}>
                                                    <RemoveBtn />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='cursor-pointer mx-auto my-3' style={{width: 'fit-content'}} onClick={() => {handleAddMore("recommendation_and_follow_ups")}}>
                        <Add />
                    </div>
                </div>

                <div className="border w-100 mt-5 mb-3 rounded current-dietary-pattern ">
                    <div className="heading py-3">
                    FOLLOW UP PROGRAMME
                    </div>
                    <div className=' '>
                        <div className='d-flex'>
                            <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '230px'}}>Follow up Consultation:</div>
                            <div className="text-break flex-grow-1 border p-2 white-space">
                                <TextArea
                                    row={2}
                                    placeholder={"Enter "}
                                    onChange={value =>setKey(["follow_up_programme", "consultation"], value)}
                                    value={content?.data?.follow_up_programme?.consultation || ''}/>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '230px'}}>Follow up Visit (Programme):</div>
                            <div className="text-break flex-grow-1 border p-2 white-space">
                                <Select
                                    className="name-select"
                                    classNamePrefix="select"
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={
                                        programList ? programList?.map(item=> ({
                                          label: item.title,
                                          value: item.title
                                        }))
                                        : []
                                    }
                                    placeholder='Select'
                                    onChange={e =>setKey(["follow_up_programme", "programme"], e.value)}
                                />
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '230px'}}>Follow up Visit (Timeline):</div>
                            <div className="text-break flex-grow-1 border p-2 white-space">
                                <TextArea
                                    row={2}
                                    placeholder={"Enter "}
                                    onChange={value =>setKey(["follow_up_programme", "timeline"], value)}
                                    value={content?.data?.follow_up_programme?.timeline || ''}/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            <div className="w-50">
            {uploadComponent}
            </div>
          </>
        }
      </div>
    )
}