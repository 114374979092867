const apiEndpoints={
    LOGIN_API: '/userAuth/api/userLogin',
    RESEND_OTP: '/userAuth/api/userLogin/resendOTP',
    VERIFY_OTP_API: '/userAuth/api/userLogin/verifyOTP',
    LOGOUT_API: '/userAuth/api/logout',
    UPDATE_PROFILE_API: '/userAuth/api/updateUserProfile',
    VIEW_PROFILE_API: '/userAuth/api/viewProfile',
    UPDATE_MOBILE_RESEND_OTP: '/userAuth/api/updatePhoneNo/resendOTP',
    UPDATE_MOBILE_NUMBER_OTP: '/userAuth/api/updatePhoneNo',
    UPDATE_MOBILE_VERIFY_OTP: '/userAuth/api/updatePhoneNo/verifyOTP',

    GET_GUEST_DETAILS_API: '/wellnessGuest/api/guestDetailsById',
    UPDATE_GUEST_DOB_API: '/userAuth/api/updateDobOfUser',
    GET_GUEST_LIST_API: '/wellnessGuest/api/v2/guestList',
    GET_GUEST_NAME_LIST_API: '/wellnessGuest/api/sessions/getGuestList',
    GET_SEARCHED_GUEST_LIST_API: '/wellnessGuest/api/searchGuest',
    VIEW_GUEST_PROFILE_API: '/wellnessGuest/api/guestDetailsById',
    GET_ANANDA_PROGRAMS_LIST: '/adminPrograms/api/getAnandaProgramList',

    //prelim assessment
    GET_PRELIM_ASSESSMENT_API: '/wellness/assessment/api/getPrelimAssessmentForGuest',
    DELETE_PRELIM_ASSESSMENT_API: '/wellness/assessment/api/deletePrelimAssessment',
    ADD_PRELIM_ASSESSMENT_API: '/wellness/assessment/api/addPrelimAssessmentGuestData',
    UPDATE_PRELIM_ASSESSMENT_API: '/wellness/assessment/api/updatePrelimAssessmentGuestData',
    DOWNLOAD_PRE_ARRIVAL_CONSULTATION_PDF_API: '/wellnessGuest/api/pdf/printPreArrivalConsultation',

    //wellbeing report
    VIEW_WELLBEING_REPORT_API: '/wellness/wellbeingReport/api/getWellbeingReportOfGuest', 
    WELLBEING_REPORT_VISIBLE_STATUS_API: '/wellness/wellbeingReport/api/updateWllbeingReportVisibleStatus',
    VIEW_FULL_WELLBEING_REPORT_API: '/wellness/wellbeingReport/api/viewFullAssessment', 
    GET_WELLBEING_REPORT_EDIT_DATA_API: '/wellness/wellbeingReport/api/viewFullAssessment',
    UPDATE_WELLBEING_REPORT_DATA_API: '/wellness/wellbeingReport/api/editGuestAssessment', 
    ASSESSMENT_SECTIONS_API: '/wellness/wellbeingReport/api/healthAssessmentSections', 
    REOPEN_ASSESSMENT_FOR_GUEST_API: '/wellness/wellbeingReport/api/reopenSelfAssessment',
    DOWNLOAD_WELLBEING_REPORT_PDF_API: '/wellnessGuest/api/pdf/printWellbeingReport',
    OPEN_HEALTH_ASSESSMENT_API: '/guestSelfAssessment/api/updateIsActiveStatusAssessment',

    //preparatory diet
    VIEW_PREPARATORY_DIET_API: '/wellness/preparatoryDiet/api/viewPreparatoryDiet',
    DELETE_PREPARATORY_DIET_API: '/wellness/preparatoryDiet/api/removePreparatoryDiet',
    UPDATE_PREPARATORY_DIET_API: '/wellness/preparatoryDiet/api/updatePreparatoryDiet',
    ADD_PREPARATORY_DIET_API: '/wellness/preparatoryDiet/api/cron/addPreparatorydiet',
    UPDATE_VISIBLE_STATUS_PREPARATORY_DIET_API: '/wellness/preparatoryDiet/api/updateVisibleStatus',

    //notes
    VIEW_CONSULTATION_NOTES_API: '/wellness/notes/api/v2/viewConsultationNotes',
    DELETE_CONSULTATION_NOTES_API: '/wellness/notes/api/deleteNotes',
    UPDATE_CONSULTATION_NOTES_API: '/wellness/notes/api/updateConsultationNotes',
    ADD_CONSULTATION_NOTES_API: '/wellness/notes/api/addConsultationNotes',
    UPDATE_VISIBLE_STATUS_CONSULTATION_NOTES_API: '/wellness/notes/api/updateConsultationNotesVisibleStatus',
    GET_PREVIOUS_CONSULTATION_NOTE_BY_TYPE_API: "/wellness/notes/api/getLastUpdatedNotes",
    GET_NOTES_TYPE_LIST_API: '/wellnessGuest/api/getNoticeType',

    //sessions
    GET_DAYWISE_USER_SCHEDULE_API: '/wellness/session/api/userDayWiseSchedule',
    VIEW_SESSION_NOTES_API: '/wellness/session/api/viewSessionNotes',
    DELETE_SESSION_NOTES_API: '/wellness/session/api/removeSessionNotes',
    ADD_SESSION_NOTES_API: '/wellness/session/api/addSessionNotes',

    //query
    GET_QUERY_LIST_API: '/guestQueryChat/api/getQueryChats',
    GET_QUERY_MESSAGE_API: '/guestQueryChat/api/getChatsMessages',
    SEND_MESSAGE_IN_QUERY_API: '/guestQueryChat/api/sendMessage',
    GET_QUERY_DETAILS_API: '/guestQueryChat/api/chatDetailsByChatId',
    UPDATE_QUERY_STATUS_API: '/wellnessGuest/api/getGroupActivityList',

    //Group activity / classes
    GET_ACTIVITY_LIST_BY_DATE_API: '/wellnessGuest/api/getGroupActivityList',
    GET_ACTIVITY_DETAILS_API: '/adminPrograms/api/getInHouseActivityDetailsById',
    GET_INTERESTED_USER_LIST_API: '/adminPrograms/api/interestedGuestList',

    //status report
    GET_WELLNESS_STATUS_REPORT_API: '/wellnessGuest/api/statusReport',
    GET_STATUS_REPORT_FILTER_LIST_API: '/wellnessGuest/api/statusReportFilterList',

    //Testimonial
    GET_TESTIMONIAL_API: '/adminReportsManagement/api/getTestimonialDetail',
    GET_PROGRAMME_FEEDBACK_API: '/adminReportsManagement/api/reports/viewFeedbackDetails',
}

export default apiEndpoints;